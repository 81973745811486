import { styled } from '@ori-ui/mui';

export const Categories = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(8),
  },
}));
