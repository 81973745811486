import type { CategoryBoxProps } from '@ori/presentation-components';

import type { ParseCategoriesParams } from './types';

/**
 * Utility to filter categories with thruthy data and map them to `CategoryBoxProps` type.
 * @returns Categories with thruthy data
 */
export const parseCategories = ({ categories }: ParseCategoriesParams): Array<Omit<CategoryBoxProps, 'buttonText'>> =>
  (categories ?? [])
    .filter((category) => !!category)
    .filter(({ landingPageId, themeTag, thumbnailUrl }) => !!landingPageId && !!themeTag && !!thumbnailUrl)
    .map(({ landingPageId, themeTag, thumbnailUrl }) => ({
      imageUrl: thumbnailUrl,
      relativeUrl: landingPageId,
      title: themeTag,
    }));
