/* istanbul ignore file */

/**
  * DO NOT EDIT!
  * This file was autogenerated by graphql-codegen.
  * Changes may cause incorrect behavior and will be lost when the code is regenerated.
  *
  * Run `npx run codegen` to regenerate.
  */


export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  JSON: { input: Record<string, any>; output: Record<string, any>; }
  Void: { input: void; output: void; }
};

/** Customers address */
export type Address = {
  readonly __typename?: 'Address';
  /** Address id */
  readonly addressId: Scalars['ID']['output'];
  /** Address type */
  readonly addressType?: Maybe<AddressType>;
  /** Area */
  readonly area?: Maybe<Scalars['String']['output']>;
  /** City */
  readonly city?: Maybe<Scalars['String']['output']>;
  /** Coordinates */
  readonly coordinates?: Maybe<Coordinates>;
  /** Country */
  readonly country?: Maybe<Scalars['String']['output']>;
  /** Delivery */
  readonly delivery?: Maybe<Scalars['String']['output']>;
  /** Delivery2 */
  readonly delivery2?: Maybe<Scalars['String']['output']>;
  /** Delivery3 */
  readonly delivery3?: Maybe<Scalars['String']['output']>;
  /** District */
  readonly district?: Maybe<Scalars['String']['output']>;
  /** Is address primary */
  readonly isPrimary?: Maybe<Scalars['Boolean']['output']>;
  /** Locality */
  readonly locality?: Maybe<Scalars['String']['output']>;
  /** Province */
  readonly province?: Maybe<Scalars['String']['output']>;
  /** Region */
  readonly region?: Maybe<Scalars['String']['output']>;
  /** Street address */
  readonly streetAddress?: Maybe<Scalars['String']['output']>;
  /** Zip code */
  readonly zipcode?: Maybe<Scalars['String']['output']>;
};

/** Customers address metadata */
export type AddressMetadata = {
  readonly __typename?: 'AddressMetadata';
  /** Address type */
  readonly addressType?: Maybe<AddressMetadataType>;
  /** Area */
  readonly area?: Maybe<AddressMetadataItem>;
  /** City */
  readonly city?: Maybe<AddressMetadataItem>;
  /** Country */
  readonly country?: Maybe<CountryMetadataItem>;
  /** Delivery */
  readonly delivery?: Maybe<AddressMetadataDelivery>;
  /** Delivery2 */
  readonly delivery2?: Maybe<AddressMetadataDelivery>;
  /** Delivery3 */
  readonly delivery3?: Maybe<AddressMetadataDelivery>;
  /** District */
  readonly district?: Maybe<AddressMetadataItem>;
  /** Is primary */
  readonly isPrimary?: Maybe<IsPrimaryMetadataItem>;
  /** KYC operations */
  readonly kycOperations?: Maybe<MetadataOperations>;
  /** Locality */
  readonly locality?: Maybe<AddressMetadataItem>;
  /** Operations */
  readonly operations?: Maybe<AddressMetadataOperations>;
  /** Province */
  readonly province?: Maybe<AddressMetadataItem>;
  /** Region */
  readonly region?: Maybe<AddressMetadataItem>;
  /** Search option */
  readonly searchOptions?: Maybe<AddressMetadataSearchOptions>;
  /** Street address */
  readonly streetAddress?: Maybe<AddressMetadataItem>;
  /** Zip code */
  readonly zipcode?: Maybe<AddressMetadataItem>;
};

/** Enum allowed search results */
export enum AddressMetadataAllowedSearchResults {
  Area = 'Area',
  City = 'City',
  Country = 'Country',
  District = 'District',
  Locality = 'Locality',
  Market = 'Market',
  Province = 'Province',
  Region = 'Region',
  Street = 'Street',
  ZipCode = 'ZipCode'
}

/** Delivery */
export type AddressMetadataDelivery = {
  readonly __typename?: 'AddressMetadataDelivery';
  /** Available options */
  readonly availableOptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is delivery available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is delivery editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Delivery validation */
  readonly validation?: Maybe<AddressMetadataDeliveryValidation>;
};

/** Delivery validation */
export type AddressMetadataDeliveryValidation = {
  readonly __typename?: 'AddressMetadataDeliveryValidation';
  /** Is delivery mandatory */
  readonly isMandatory?: Maybe<Scalars['Boolean']['output']>;
  /** Delivery maximal length */
  readonly maxLength?: Maybe<Scalars['Int']['output']>;
  /** Delivery minimal length */
  readonly minLength?: Maybe<Scalars['Int']['output']>;
  /** Regular expression */
  readonly regularExpression?: Maybe<Scalars['String']['output']>;
};

export type AddressMetadataItem = {
  readonly __typename?: 'AddressMetadataItem';
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is item available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is item editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Item validation */
  readonly validation?: Maybe<AddressMetadataValidation>;
};

/** Operation */
export type AddressMetadataOperation = {
  readonly __typename?: 'AddressMetadataOperation';
  /** Is operation enabled */
  readonly isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** List of reasons */
  readonly reasons?: Maybe<ReadonlyArray<Maybe<AddressMetadataOperationReason>>>;
};

/** Operation reasons */
export type AddressMetadataOperationReason = {
  readonly __typename?: 'AddressMetadataOperationReason';
  /** Code */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** Message */
  readonly message?: Maybe<Scalars['String']['output']>;
};

/** Operations */
export type AddressMetadataOperations = {
  readonly __typename?: 'AddressMetadataOperations';
  /** Create */
  readonly create?: Maybe<AddressMetadataOperation>;
  /** Delete */
  readonly delete?: Maybe<AddressMetadataOperation>;
  /** Delete primary */
  readonly deletePrimary?: Maybe<AddressMetadataOperation>;
  /** Read */
  readonly read?: Maybe<AddressMetadataOperation>;
  /** Update */
  readonly update?: Maybe<AddressMetadataOperation>;
};

/** Search options */
export type AddressMetadataSearchOptions = {
  readonly __typename?: 'AddressMetadataSearchOptions';
  /** Allowed search results */
  readonly allowedSearchResults?: Maybe<ReadonlyArray<Maybe<AddressMetadataAllowedSearchResults>>>;
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is search options enabled */
  readonly isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Maximum number of results offered during search */
  readonly maxResults?: Maybe<Scalars['Int']['output']>;
};

/** Address type */
export type AddressMetadataType = {
  readonly __typename?: 'AddressMetadataType';
  /** Available options */
  readonly availableOptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Is address type available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is address type editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Address type validation */
  readonly validation?: Maybe<AddressMetadataTypeValidation>;
};

/** Address type validation */
export type AddressMetadataTypeValidation = {
  readonly __typename?: 'AddressMetadataTypeValidation';
  /** Is field mandatory */
  readonly isMandatory?: Maybe<Scalars['Boolean']['output']>;
};

/** Address field validation */
export type AddressMetadataValidation = {
  readonly __typename?: 'AddressMetadataValidation';
  /** Is address match required */
  readonly isAddressMatchRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Is field mandatory */
  readonly isMandatory?: Maybe<Scalars['Boolean']['output']>;
  /** Field maximal length */
  readonly maxLength?: Maybe<Scalars['Int']['output']>;
  /** Field minimal length */
  readonly minLength?: Maybe<Scalars['Int']['output']>;
  /** Regular expression */
  readonly regularExpression?: Maybe<Scalars['String']['output']>;
};

/** Enum address type */
export enum AddressType {
  Channel = 'Channel',
  Delivery = 'Delivery',
  Foreign = 'Foreign',
  Home = 'Home',
  Kyc = 'Kyc',
  Other = 'Other'
}

/** AppLink */
export type AppLink = {
  readonly __typename?: 'AppLink';
  /** Deep link */
  readonly deepLink?: Maybe<Scalars['String']['output']>;
  /** Link */
  readonly link?: Maybe<Scalars['String']['output']>;
};

/** Group of application resources */
export type Application = {
  readonly __typename?: 'Application';
  /** Bazaarvoice configuration */
  readonly bazaarvoiceConfiguration?: Maybe<BazaarvoiceConfiguration>;
  /** Breadcrumb links for a page URL */
  readonly breadcrumbLinks?: Maybe<BreadcrumbLinks>;
  /** Editorial landing page */
  readonly editorialLandingPage?: Maybe<EditorialLandingPage>;
  /** Editorial landing pages */
  readonly editorialLandingPages?: Maybe<ReadonlyArray<Maybe<EditorialLandingPage>>>;
  /** Editorial page */
  readonly editorialPage?: Maybe<EditorialPage>;
  /** Editorial pages */
  readonly editorialPages?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Feedback benefit banners */
  readonly feedbackBenefitBanners?: Maybe<ReadonlyArray<Maybe<FeedbackBenefitBanner>>>;
  /** Footer */
  readonly footer?: Maybe<Footer>;
  /** Frontpage v3 content */
  readonly frontpage?: Maybe<Frontpage>;
  /** Ingredient library */
  readonly ingredientLibrary?: Maybe<IngredientLibrary>;
  /** Makeup Try On */
  readonly makeupTryOnConfiguration?: Maybe<MakeupTryOnConfiguration>;
  /** Navigation */
  readonly navigation?: Maybe<NavigationCollection>;
  /** Navigations */
  readonly navigations?: Maybe<ReadonlyArray<Maybe<NavigationCollection>>>;
  /** Olapic configuration */
  readonly olapic?: Maybe<OlapicConfiguration>;
  /** Navigation menu for a single page */
  readonly pageNavigation?: Maybe<PageNavigation>;
  /** Navigation menu for multiple pages */
  readonly pageNavigations?: Maybe<ReadonlyArray<Maybe<PageNavigation>>>;
  /** Personal product list configuration */
  readonly personalProductListConfiguration?: Maybe<PersonalProductListConfiguration>;
  /** Product detail page */
  readonly productDetailPage?: Maybe<ProductDetailPage>;
  /** Product listing page */
  readonly productListingPage?: Maybe<ProductListingPage>;
  /** Product Search Page */
  readonly productSearchPage?: Maybe<ProductSearchPage>;
  /** Share and earn page */
  readonly shareAndEarn?: Maybe<ShareAndEarn>;
  /** Links meant for user sharing */
  readonly sharingLinks?: Maybe<SharingLinks>;
  /** Video Shopping */
  readonly videoShopping?: Maybe<VideoShopping>;
  /** Wellness Club */
  readonly wellnessClub?: Maybe<WellnessClub>;
};


/** Group of application resources */
export type ApplicationBreadcrumbLinksArgs = {
  pageUrl: Scalars['String']['input'];
};


/** Group of application resources */
export type ApplicationEditorialLandingPageArgs = {
  channelType?: InputMaybe<EditorialChannelType>;
  landingPageId: Scalars['ID']['input'];
};


/** Group of application resources */
export type ApplicationEditorialLandingPagesArgs = {
  channelType?: InputMaybe<EditorialChannelType>;
  landingPageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


/** Group of application resources */
export type ApplicationEditorialPageArgs = {
  channelType?: InputMaybe<EditorialChannelType>;
  pageId: Scalars['ID']['input'];
};


/** Group of application resources */
export type ApplicationEditorialPagesArgs = {
  channelType?: InputMaybe<EditorialChannelType>;
  pageIds: ReadonlyArray<InputMaybe<Scalars['ID']['input']>>;
};


/** Group of application resources */
export type ApplicationFeedbackBenefitBannersArgs = {
  bannerTypes?: InputMaybe<ReadonlyArray<InputMaybe<FeedbackBenefitBannerType>>>;
};


/** Group of application resources */
export type ApplicationNavigationArgs = {
  customerTypeId?: InputMaybe<Scalars['Int']['input']>;
  navigationType?: InputMaybe<NavigationType>;
  nodeId?: InputMaybe<Scalars['String']['input']>;
  visitorSegment?: InputMaybe<VisitorSegment>;
};


/** Group of application resources */
export type ApplicationNavigationsArgs = {
  customerTypeId?: InputMaybe<Scalars['Int']['input']>;
  navigationTypes?: InputMaybe<ReadonlyArray<InputMaybe<NavigationType>>>;
  nodeId?: InputMaybe<Scalars['String']['input']>;
  visitorSegment?: InputMaybe<VisitorSegment>;
};


/** Group of application resources */
export type ApplicationOlapicArgs = {
  categoryId?: InputMaybe<Scalars['String']['input']>;
  olapicType: OlapicTypeEnum;
  productCode?: InputMaybe<Scalars['ID']['input']>;
};


/** Group of application resources */
export type ApplicationPageNavigationArgs = {
  pageId?: InputMaybe<Scalars['String']['input']>;
};


/** Group of application resources */
export type ApplicationPageNavigationsArgs = {
  pageIds?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
};


/** Group of application resources */
export type ApplicationProductDetailPageArgs = {
  productCode: Scalars['ID']['input'];
};


/** Group of application resources */
export type ApplicationProductListingPageArgs = {
  pageId: Scalars['ID']['input'];
  skip?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};

/** Customers approvals */
export type Approval = {
  readonly __typename?: 'Approval';
  /** Blocks the consultant's guarantee and SPO manager from ordering on their behalf */
  readonly blockGuaranteeOrdering?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving info emails/SMS */
  readonly contact?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving emails/SMS from beauty consultant */
  readonly marketingConsultant?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving marketing information from external sources */
  readonly marketingExternal?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving marketing information from Oriflame */
  readonly marketingOriflame?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for receiving newsletters */
  readonly newsletters?: Maybe<Scalars['Boolean']['output']>;
  /** Customer cannot be selected as sponsor by different subjects (upline consultant, self. registration, locator, ...) */
  readonly optedOutOfSponsorSelect?: Maybe<Scalars['Boolean']['output']>;
  /** Nova customer approval for share Order history with allocated beauty Consultant */
  readonly orderHistory?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for displaying personal data */
  readonly personalData?: Maybe<Scalars['Boolean']['output']>;
  /** Approval for publication in Oriflame materials */
  readonly publication?: Maybe<Scalars['Boolean']['output']>;
  /** Leader approval for upline Leader to browse leader downline members */
  readonly uplineLeaderBrowse?: Maybe<Scalars['Boolean']['output']>;
  /** Leader approval for upline Leader to display leader's data */
  readonly uplineLeaderData?: Maybe<Scalars['Boolean']['output']>;
};

export type Article = {
  readonly __typename?: 'Article';
  /** Type of a article */
  readonly articleType?: Maybe<ArticleType>;
  /** Title of the category of article */
  readonly categoryTitle?: Maybe<Scalars['String']['output']>;
  /** Article's image */
  readonly image?: Maybe<ArticleImage>;
  /** Introductory text from article */
  readonly introText?: Maybe<Scalars['String']['output']>;
  /** Flag indicating if article contains video */
  readonly isVideoArticle?: Maybe<Scalars['Boolean']['output']>;
  /** Link to the article */
  readonly link?: Maybe<Link>;
  /** Collection of products related to the article */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Date of article's publication */
  readonly published?: Maybe<Scalars['Date']['output']>;
  /** Title of the article */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ArticleContent = {
  readonly __typename?: 'ArticleContent';
  /** Placeholder. This type has yet to be implemented. */
  readonly placeholder?: Maybe<Scalars['String']['output']>;
};

export type ArticleImage = {
  readonly __typename?: 'ArticleImage';
  /** Alternative text of an image */
  readonly alt?: Maybe<Scalars['String']['output']>;
  /** Image's url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

export enum ArticleType {
  /** Articles from Beauty Edit section */
  BeautyEdit = 'BeautyEdit',
  /** Articles related to products */
  RelatedToProduct = 'RelatedToProduct',
  /** Default value - not for use with API. Exists to detect errors in parsing values */
  Undefined = 'Undefined'
}

export type BackInStockAvailability = {
  readonly __typename?: 'BackInStockAvailability';
  /** Flag indicating that back in stock notification is available */
  readonly showBackInStockNotification?: Maybe<Scalars['Boolean']['output']>;
};

/** Channel to identify how we should notify user */
export enum BackInStockNotificationChannel {
  /** We will notify customer on provided email */
  Email = 'Email',
  /** We will notify customer on provided telephone */
  Sms = 'Sms'
}

/** Input to let user subscribe to when the product is back in stock */
export type BackInStockNotificationSubscriptionInput = {
  /** Email value for notificationChannel.Email */
  readonly email?: InputMaybe<Scalars['String']['input']>;
  /** The product code to watch */
  readonly itemCode: Scalars['String']['input'];
  /** Flag indicating the channel for notification */
  readonly notificationChannel: BackInStockNotificationChannel;
  /** Telephone value for notificationChannel.Telephone */
  readonly telephoneNumber?: InputMaybe<Scalars['String']['input']>;
};

/** Background types */
export enum BackgroundType {
  /** Color as a background */
  Color = 'Color',
  /** Image as a background */
  Image = 'Image',
  /** No background */
  None = 'None',
  /** Video as a background */
  Video = 'Video'
}

export type Bank = {
  readonly __typename?: 'Bank';
  /** Id of the Bank */
  readonly bankId: Scalars['Int']['output'];
  /** Bank code */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** Name of the Bank */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** SWIFT/BIC code */
  readonly swiftBic?: Maybe<Scalars['String']['output']>;
};

/** Bank account */
export type BankAccount = {
  readonly __typename?: 'BankAccount';
  /** The Bank */
  readonly bank?: Maybe<Bank>;
  /** Bank Account ID */
  readonly bankAccountId: Scalars['ID']['output'];
  /** Branch of the Bank */
  readonly bankBranch?: Maybe<BankBranch>;
  /** Subbranch of the Bank */
  readonly bankSubBranch?: Maybe<Scalars['String']['output']>;
  /** Bank account number */
  readonly number?: Maybe<Scalars['String']['output']>;
  /** Bank account owner */
  readonly owner?: Maybe<Scalars['String']['output']>;
};

export type BankBranch = {
  readonly __typename?: 'BankBranch';
  /** Id of the bank branch */
  readonly bankBranchId: Scalars['Int']['output'];
  /** Bank branch code */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** Bank branch name */
  readonly name?: Maybe<Scalars['String']['output']>;
};

/** Bazaarvoice configuration integration */
export type BazaarvoiceConfiguration = {
  readonly __typename?: 'BazaarvoiceConfiguration';
  /** Checks if Conversations Platform is enabled */
  readonly conversationsPlatformEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Checks if Dream Catcher is enabled */
  readonly dreamCatcherEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Dream Catcher Product Code */
  readonly dreamCatcherProductCode?: Maybe<Scalars['String']['output']>;
  /** Legal notice read-more link */
  readonly legalNoticeLink?: Maybe<Link>;
  /** Legal notice text */
  readonly legalNoticeText?: Maybe<Scalars['String']['output']>;
  /** Checks if Questions And Answers are enabled */
  readonly questionsAndAnswersEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Checks if Questions And Answers are Enabled For Segments */
  readonly questionsAndAnswersEnabledForSegments?: Maybe<Scalars['Boolean']['output']>;
  /** Checks if Ratings And Reviews are enabled */
  readonly ratingsAndReviewsEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Url for Submission Client Script */
  readonly submissionClientScriptUrl?: Maybe<Scalars['String']['output']>;
  /** Submission Page Id */
  readonly submissionPageId?: Maybe<Scalars['String']['output']>;
  /** Checks if Transaction Tracking is enabled */
  readonly transactionTrackingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** General Url for bazaarvoice */
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type BeautyEdit = {
  readonly __typename?: 'BeautyEdit';
  /** Number of acvaliable articles */
  readonly editorialArticles?: Maybe<ReadonlyArray<Maybe<Article>>>;
  /** Url of the main image of Beauty Edit section */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Main text of Beauty Edit section. */
  readonly text?: Maybe<Scalars['String']['output']>;
};


export type BeautyEditEditorialArticlesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type BeautyVideo = {
  readonly __typename?: 'BeautyVideo';
  /** Link to Beauty Edit section */
  readonly link?: Maybe<Link>;
  /** Video displayed in the section */
  readonly video?: Maybe<Video>;
};

export type BenefitBanner = {
  readonly __typename?: 'BenefitBanner';
  /** Title */
  readonly messageTitle?: Maybe<Scalars['String']['output']>;
  /** URL */
  readonly messageUrl?: Maybe<Scalars['String']['output']>;
};

export type BestOffersBanner = {
  readonly __typename?: 'BestOffersBanner';
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['Date']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['Date']['output']>;
};

/** Bestseller products */
export type BestsellerProducts = {
  readonly __typename?: 'BestsellerProducts';
  /** Banner of the bestseller products. */
  readonly banner?: Maybe<ProductsPromotionBanner>;
  /** Headline of the bestseller products. */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** List of bestseller products. */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

export type Brand = {
  readonly __typename?: 'Brand';
  /** Brand id */
  readonly brandId: Scalars['ID']['output'];
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Background Image URL */
  readonly image?: Maybe<Scalars['String']['output']>;
  /** Collection of logo images with sizes */
  readonly logo?: Maybe<ReadonlyArray<Maybe<SizedImage>>>;
  /** Localized name of the brand */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Original name of the brand used for product/concept indexing */
  readonly originalName?: Maybe<Scalars['String']['output']>;
  /** Collection of sub-brands */
  readonly subBrands?: Maybe<ReadonlyArray<Maybe<Brand>>>;
  /** Taxonomy id */
  readonly taxonomyId: Scalars['ID']['output'];
  /** SEO headline text from product area */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Title image */
  readonly titleImage?: Maybe<Scalars['String']['output']>;
  /** Brand Page url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** Breadcrumb links */
export type BreadcrumbLinks = {
  readonly __typename?: 'BreadcrumbLinks';
  /** Collection of links for the breadcrumb navigation */
  readonly links?: Maybe<ReadonlyArray<Maybe<Link>>>;
};

/** Component of a product bundle */
export type BundleComponent = {
  readonly __typename?: 'BundleComponent';
  /** Product included in a bundle */
  readonly product?: Maybe<Product>;
  /** Quantity of a product in a bundle */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

export type CampaignBanner = {
  readonly __typename?: 'CampaignBanner';
  /** Background color -eg. red, grey */
  readonly backgroundColor?: Maybe<Scalars['String']['output']>;
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Short text of the banner */
  readonly shortText?: Maybe<Scalars['String']['output']>;
  /** Tagline of the banner */
  readonly tagline?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['Date']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['Date']['output']>;
};

export type Catalogue = {
  readonly __typename?: 'Catalogue';
  /** catalogue code */
  readonly catalogueCode: Scalars['ID']['output'];
};

export type CatalogueOffer = {
  readonly __typename?: 'CatalogueOffer';
  /** Benefit Product */
  readonly benefits?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProductBenefit>>>;
  /** Quantity of benefit */
  readonly benefitsQuantity?: Maybe<Scalars['Int']['output']>;
  /** Concept linked to the offer */
  readonly concepts?: Maybe<ReadonlyArray<Maybe<CatalogueOffersConcept>>>;
  /** Benefit concepts */
  readonly conceptsBenefits?: Maybe<ReadonlyArray<Maybe<CatalogueOffersConceptBenefit>>>;
  /** Percent of the discount */
  readonly discountPercent?: Maybe<Scalars['Float']['output']>;
  /** Quantity of missing products */
  readonly missingQuantity?: Maybe<Scalars['Int']['output']>;
  /** Offer id */
  readonly offerId?: Maybe<Scalars['Int']['output']>;
  /** Product linked to the offer */
  readonly products?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProduct>>>;
  /** Total price */
  readonly totalPrice?: Maybe<Scalars['Float']['output']>;
  /** Total price after discount */
  readonly totalPriceAfterDiscount?: Maybe<Scalars['Float']['output']>;
  /** Type of the offer */
  readonly type?: Maybe<CatalogueOfferType>;
};

export enum CatalogueOfferType {
  /** Special offer for buying product */
  Buy = 'Buy',
  /** Choose from products */
  Choose = 'Choose',
  /** Choose a product and choose another */
  ChooseAndChoose = 'ChooseAndChoose',
  /** Choose a product and get another */
  ChooseAndGet = 'ChooseAndGet',
  /** Get a product */
  Get = 'Get',
  /** No type of catalogue offer was specified */
  None = 'None'
}

export type CatalogueOffersConcept = {
  readonly __typename?: 'CatalogueOffersConcept';
  /** Buisness points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Offer concept */
  readonly concept?: Maybe<Concept>;
  /** Price of the concept */
  readonly price?: Maybe<Scalars['Float']['output']>;
  /** Quantity of the product */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  /** Shades of the product */
  readonly shades?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProduct>>>;
};

export type CatalogueOffersConceptBenefit = {
  readonly __typename?: 'CatalogueOffersConceptBenefit';
  /** Buisness points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Concept */
  readonly concept?: Maybe<Concept>;
  /** Price */
  readonly price?: Maybe<Scalars['Float']['output']>;
  /** Purchase price */
  readonly purchasePrice?: Maybe<Scalars['Float']['output']>;
  /** Quantity of the concept benefit */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  /** Shades of the concept benefit */
  readonly shades?: Maybe<ReadonlyArray<Maybe<CatalogueOffersProductBenefit>>>;
};

export type CatalogueOffersProduct = {
  readonly __typename?: 'CatalogueOffersProduct';
  /** Buisness points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Price of the product */
  readonly price?: Maybe<Scalars['Float']['output']>;
  /** Offer product */
  readonly product?: Maybe<Product>;
  /** Quantity of the product */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

export type CatalogueOffersProductBenefit = {
  readonly __typename?: 'CatalogueOffersProductBenefit';
  /** Buisness points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Is purchased flag */
  readonly isPurchased?: Maybe<Scalars['Boolean']['output']>;
  /** Price */
  readonly price?: Maybe<Scalars['Float']['output']>;
  /** Product */
  readonly product?: Maybe<Product>;
  /** Purchase price of the product benefit */
  readonly purchasePrice?: Maybe<Scalars['Float']['output']>;
  /** Quantity of the product benefit */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
  /** Replaced product */
  readonly replacedPoduct?: Maybe<Product>;
};

/** Category type deifnition */
export type Category = {
  readonly __typename?: 'Category';
  /** Expiration date description used for category */
  readonly expirationDateDescription?: Maybe<Scalars['String']['output']>;
  /** Category Id */
  readonly id: Scalars['ID']['output'];
  /** Category image */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Localized category name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Collection of Navigation Tags */
  readonly navigationTags?: Maybe<ReadonlyArray<Maybe<NavigationTag>>>;
  /** Original name of the category used for product/concept indexing */
  readonly originalName?: Maybe<Scalars['String']['output']>;
  /** Collection of subcategories */
  readonly subCategories?: Maybe<ReadonlyArray<Maybe<Category>>>;
  /** Taxonomy ID of the category */
  readonly taxonomyId: Scalars['ID']['output'];
};

export type CategoryBanner = {
  readonly __typename?: 'CategoryBanner';
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Short text of the banner */
  readonly shortText?: Maybe<Scalars['String']['output']>;
  /** Tagline of the banner */
  readonly tagline?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['Date']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['Date']['output']>;
};

export enum ChannelType {
  Mv7 = 'MV7',
  Website = 'Website'
}

export type Chat = {
  readonly __typename?: 'Chat';
  /** ChatBot */
  readonly chatBot?: Maybe<ChatBot>;
  /** Genesys */
  readonly genesys?: Maybe<Genesys>;
};


export type ChatChatBotArgs = {
  isAuthenticated?: InputMaybe<Scalars['Boolean']['input']>;
  pageUrl?: InputMaybe<Scalars['String']['input']>;
  visitorSegment?: InputMaybe<VisitorSegment>;
};


export type ChatGenesysArgs = {
  isAuthenticated?: InputMaybe<Scalars['Boolean']['input']>;
  pageUrl?: InputMaybe<Scalars['String']['input']>;
};

export type ChatBot = {
  readonly __typename?: 'ChatBot';
  /** Chatbot configuration */
  readonly configuration?: Maybe<ChatBotConfiguration>;
};

export type ChatBotConfiguration = {
  readonly __typename?: 'ChatBotConfiguration';
  /** Contact support Url */
  readonly contactSupportUrl?: Maybe<Scalars['String']['output']>;
  /** Direct line channel secret */
  readonly directLineChannelSecret?: Maybe<Scalars['String']['output']>;
  /** Is chat handover */
  readonly isChatHandover?: Maybe<Scalars['Boolean']['output']>;
  /** Is test mode active */
  readonly isTestModeActive?: Maybe<Scalars['Boolean']['output']>;
  /** Message history limit */
  readonly messageHistoryLimit?: Maybe<Scalars['Int']['output']>;
  /** Chat bot mode */
  readonly mode?: Maybe<ChatBotMode>;
};

export enum ChatBotMode {
  AlwaysEnabled = 'AlwaysEnabled',
  Disabled = 'Disabled',
  NonBusinessHoursOnly = 'NonBusinessHoursOnly'
}

/** Color type */
export enum ColorType {
  Brown = 'Brown',
  Green = 'Green',
  White = 'White'
}

/** Concept of products */
export type Concept = {
  readonly __typename?: 'Concept';
  /** About concept */
  readonly about?: Maybe<Scalars['String']['output']>;
  /** Localized ingredients text */
  readonly activeIngredients?: Maybe<Scalars['String']['output']>;
  /** Availability flags */
  readonly availability?: Maybe<ConceptAvailability>;
  /** Collection of localized benefits texts */
  readonly benefits?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Id of the brand */
  readonly brand?: Maybe<Brand>;
  /** Collection of categories */
  readonly categories?: Maybe<ReadonlyArray<Maybe<Category>>>;
  /** Code that identifies the concept and the first product of the concept */
  readonly conceptCode: Scalars['ID']['output'];
  /** Localized description of main product (concept) */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Formatted price of a concept */
  readonly formattedPrice?: Maybe<ConceptFormattedPrice>;
  /** Collection of product codes of the given concept */
  readonly hashCode?: Maybe<Scalars['Int']['output']>;
  /** Localized how to use text */
  readonly howToUse?: Maybe<Scalars['String']['output']>;
  /** Collection of how to use sections data (Novage+). */
  readonly howToUseSections?: Maybe<ReadonlyArray<Maybe<ConceptHowToUseSection>>>;
  /** Image url. Taken from the first (random) product. */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Localized info text */
  readonly info?: Maybe<Scalars['String']['output']>;
  /** Comma separated list of ingredients */
  readonly ingredients?: Maybe<Scalars['String']['output']>;
  /** Flag indicating whether the concept is discontinued. True - all the products from the concept are discontinued. False - at least one of the product from the concept is not discontinued */
  readonly isDiscontinued?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the concept contains more than one product */
  readonly isMultiProduct?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the concept is a outlet. True - outlet concept. False - concept is not a outlet. */
  readonly isOutlet?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the concept is a sample. True - sample concept. False - concept is not a sample */
  readonly isSample?: Maybe<Scalars['Boolean']['output']>;
  /** List of concepts labels which have true value */
  readonly labels?: Maybe<ReadonlyArray<Maybe<ProductLabel>>>;
  /** Localized name of main product (concept) */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Global product name */
  readonly originalName?: Maybe<Scalars['String']['output']>;
  /** Permission flags which indicates, if the concept can be displayed */
  readonly permission?: Maybe<ConceptPermission>;
  /** Concept price */
  readonly price?: Maybe<ConceptPrice>;
  /** Primary product */
  readonly primaryProduct?: Maybe<Product>;
  /**
   * Description of the main product
   * @deprecated Use 'description' field instead
   */
  readonly productDescription?: Maybe<Scalars['String']['output']>;
  /**
   * Name of the primary product
   * @deprecated Use 'name' field instead
   */
  readonly productName?: Maybe<Scalars['String']['output']>;
  /** Localized product story. */
  readonly productStory?: Maybe<Scalars['String']['output']>;
  /** Collection of Products */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Average Rating */
  readonly ratingAverage?: Maybe<Scalars['Float']['output']>;
  /** Count of Ratings */
  readonly ratingCount?: Maybe<Scalars['Int']['output']>;
  /** Count of Ratings with review text */
  readonly ratingsWithReviewCount?: Maybe<Scalars['Int']['output']>;
  /** Flag indicating if concept should be shown on site */
  readonly showOnSite?: Maybe<Scalars['Boolean']['output']>;
  /** Id of the subbrand */
  readonly subBrand?: Maybe<Brand>;
  /** Thumbnail image url */
  readonly thumbnailImageUrl?: Maybe<Scalars['String']['output']>;
};

export type ConceptAvailability = {
  readonly __typename?: 'ConceptAvailability';
  /** When item is out of stock, and can be backordered */
  readonly isBackorder?: Maybe<Scalars['Boolean']['output']>;
  /** Is bundle */
  readonly isBundle?: Maybe<Scalars['Boolean']['output']>;
  /** Introduction date of product is close */
  readonly isComingSoon?: Maybe<Scalars['Boolean']['output']>;
  /** Check if it is deleted */
  readonly isDeleted?: Maybe<Scalars['Boolean']['output']>;
  /** Specified by number of days (sitecore) before disco date */
  readonly isLastChance?: Maybe<Scalars['Boolean']['output']>;
  /** Current ATP is lower than 24hr sales history */
  readonly isLowStock?: Maybe<Scalars['Boolean']['output']>;
  /** Number of days (sitecore) after intro date */
  readonly isNew?: Maybe<Scalars['Boolean']['output']>;
  /** Concept is discontinued */
  readonly isNoLongerForSale?: Maybe<Scalars['Boolean']['output']>;
  /** Product cant be sold, price = 0, or is deleted */
  readonly isNotSoldOnline?: Maybe<Scalars['Boolean']['output']>;
  /** Is not standard item, catalogue offer for specific period */
  readonly isOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Out of stock */
  readonly isOutOfStock?: Maybe<Scalars['Boolean']['output']>;
  /** Is sales limited */
  readonly isSalesLimited?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Out of stock and discontinued date is older than today
   * @deprecated Label discontinued
   */
  readonly isSoldOut?: Maybe<Scalars['Boolean']['output']>;
  /** Product is Super Deal */
  readonly isSuperDeal?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Not out of stock, today is newer than discontinued date, or intro and disco date are from each other more than configurable value (sitecore)
   * @deprecated Label discontinued
   */
  readonly isUntilStockLasts?: Maybe<Scalars['Boolean']['output']>;
};

export type ConceptFormattedPrice = {
  readonly __typename?: 'ConceptFormattedPrice';
  /** Timestamp of when price was calculated */
  readonly dateCalculated?: Maybe<Scalars['Date']['output']>;
  /** Object containing concept's prices */
  readonly price?: Maybe<FormattedPrice>;
};

export type ConceptHowToUseSection = {
  readonly __typename?: 'ConceptHowToUseSection';
  /** Bullet List */
  readonly bulletList?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Icon URL */
  readonly iconUrl?: Maybe<Scalars['String']['output']>;
  /** Image URL */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ConceptPermission = {
  readonly __typename?: 'ConceptPermission';
  /** Concept can be displayed on web site or any other mobile application. Product display might not be available if the flag is set to false. */
  readonly canDisplay?: Maybe<Scalars['Boolean']['output']>;
};

export type ConceptPrice = {
  readonly __typename?: 'ConceptPrice';
  /** Currency symbol, for example: "€", "$" */
  readonly currency?: Maybe<Scalars['String']['output']>;
  /** Date calculated */
  readonly dateCalculated?: Maybe<Scalars['Date']['output']>;
  /** Prices */
  readonly price?: Maybe<Price>;
};

/** Consultant settings */
export type ConsultantSetting = {
  readonly __typename?: 'ConsultantSetting';
  /** Format of setting */
  readonly format?: Maybe<SettingsFormatType>;
  /** Content of setting */
  readonly setting?: Maybe<Scalars['String']['output']>;
  /** Timestamp */
  readonly timestamp?: Maybe<Scalars['Date']['output']>;
};

export type Coordinates = {
  readonly __typename?: 'Coordinates';
  /** North–south position of a point on the Earth's surface */
  readonly latitude?: Maybe<Scalars['Float']['output']>;
  /** East–west position of a point on the Earth's surface */
  readonly longitude?: Maybe<Scalars['Float']['output']>;
};

export type CountryMetadataItem = {
  readonly __typename?: 'CountryMetadataItem';
  /** Available options */
  readonly availableOptions?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is item available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is item editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
  /** Item validation */
  readonly validation?: Maybe<AddressMetadataValidation>;
};

/** Customer */
export type Customer = {
  readonly __typename?: 'Customer';
  /** Address metadata */
  readonly addressMetadata?: Maybe<AddressMetadata>;
  /** Addresses */
  readonly addresses?: Maybe<ReadonlyArray<Maybe<Address>>>;
  /** Alias */
  readonly alias?: Maybe<Scalars['String']['output']>;
  /** Approval */
  readonly approval?: Maybe<Approval>;
  /** Bank Accounts */
  readonly bankAccounts?: Maybe<ReadonlyArray<Maybe<BankAccount>>>;
  /** Personal beauty store */
  readonly beautyStore?: Maybe<PersonalBeautyStore>;
  /** Birth date */
  readonly birthDate?: Maybe<Scalars['String']['output']>;
  /** Career title */
  readonly careerTitle?: Maybe<Scalars['String']['output']>;
  /** Consultant number */
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  /** Customer ID */
  readonly customerId: Scalars['ID']['output'];
  /** Customer type */
  readonly customerType?: Maybe<CustomerType>;
  /** Customers direct sponsor */
  readonly directSponsor?: Maybe<Sponsor>;
  /** Display name */
  readonly displayName?: Maybe<Scalars['String']['output']>;
  /** Emails */
  readonly emails?: Maybe<ReadonlyArray<Maybe<Email>>>;
  /** Ewallet Accounts */
  readonly ewalletAccounts?: Maybe<ReadonlyArray<Maybe<EWalletAccount>>>;
  /** Customers first name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** Gender */
  readonly gender?: Maybe<Gender>;
  /** Last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** Passport number */
  readonly passportNumber?: Maybe<Scalars['String']['output']>;
  /** Phones */
  readonly phones?: Maybe<ReadonlyArray<Maybe<Phone>>>;
  /** Pin */
  readonly pin?: Maybe<Scalars['String']['output']>;
  /** Preferred language */
  readonly preferredLanguage?: Maybe<Scalars['String']['output']>;
  /** Product lists */
  readonly productLists?: Maybe<ReadonlyArray<Maybe<ProductList>>>;
  /** Consultant's settings */
  readonly settings?: Maybe<ConsultantSetting>;
  /** Tax classification */
  readonly taxClassification?: Maybe<TaxClassification>;
  /** Tax code */
  readonly taxCode?: Maybe<Scalars['String']['output']>;
  /** Tax code 2 */
  readonly taxCode2?: Maybe<Scalars['String']['output']>;
  /** Customers title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Customers unique id */
  readonly uniqueId?: Maybe<Scalars['String']['output']>;
  /** Live video shows */
  readonly videoShows?: Maybe<ReadonlyArray<Maybe<VideoShow>>>;
  /** Visitor segment */
  readonly visitorSegment?: Maybe<VisitorSegment>;
};


/** Customer */
export type CustomerProductListsArgs = {
  productListType: ProductListType;
};


/** Customer */
export type CustomerSettingsArgs = {
  settingId: Scalars['ID']['input'];
  settingsClient: SettingsClientType;
};

/** Enum customer category */
export enum CustomerCategory {
  Anonymous = 'Anonymous',
  Consultant = 'Consultant',
  Employee = 'Employee',
  EndConsumer = 'EndConsumer',
  EndCustomer = 'EndCustomer',
  Invitation = 'Invitation',
  Other = 'Other'
}

/** Customer type */
export type CustomerType = {
  readonly __typename?: 'CustomerType';
  /** Customer category */
  readonly customerCategory?: Maybe<CustomerCategory>;
  /** Customer type id */
  readonly customerTypeId?: Maybe<Scalars['Int']['output']>;
  /** Descriptions */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Is customer vip */
  readonly isVipCustomer?: Maybe<Scalars['Boolean']['output']>;
};

/** DigialCatalog promotions */
export type DigitalPromotions = {
  readonly __typename?: 'DigitalPromotions';
  /** Best offers banners */
  readonly bestOffersBanners?: Maybe<ReadonlyArray<Maybe<BestOffersBanner>>>;
  /** Best offers products */
  readonly bestOffersProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Campaign banners */
  readonly campaignBanners?: Maybe<ReadonlyArray<Maybe<CampaignBanner>>>;
  /** Campaign products */
  readonly campaignProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Category banners */
  readonly categoryBanners?: Maybe<ReadonlyArray<Maybe<CategoryBanner>>>;
  /** Category products */
  readonly categoryProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Hero banners */
  readonly heroBanners?: Maybe<ReadonlyArray<Maybe<HeroBanner>>>;
  /** Hero products */
  readonly heroProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Whats new banners */
  readonly whatsNewBanners?: Maybe<ReadonlyArray<Maybe<WhatsNewBanner>>>;
  /** WhatsNew products */
  readonly whatsNewProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

/** Describe customer document */
export type DocumentInfo = {
  readonly __typename?: 'DocumentInfo';
  /** Document id */
  readonly documentId?: Maybe<Scalars['Int']['output']>;
  /** Document file name */
  readonly fileName?: Maybe<Scalars['String']['output']>;
  /** Document file size. */
  readonly fileSize?: Maybe<Scalars['Int']['output']>;
};

/** eWallet account */
export type EWalletAccount = {
  readonly __typename?: 'EWalletAccount';
  /** eWallet account name */
  readonly accountName?: Maybe<Scalars['String']['output']>;
  /** eWallet account ID */
  readonly eWalletAccountId: Scalars['ID']['output'];
  /** eWallet account owner */
  readonly owner?: Maybe<Scalars['String']['output']>;
  /** eWallet type */
  readonly type?: Maybe<EWalletType>;
};

/** eWallet type */
export enum EWalletType {
  Revolut = 'Revolut'
}

/** Editorial banner */
export type EditorialBanner = {
  readonly __typename?: 'EditorialBanner';
  /** Banner Id */
  readonly id: Scalars['ID']['output'];
  /** Banner layout */
  readonly layout?: Maybe<EditorialBannerLayoutEnum>;
  /** Banner modules */
  readonly modules?: Maybe<ReadonlyArray<Maybe<EditorialBannerModule>>>;
};

/** Color */
export type EditorialBannerColor = {
  readonly __typename?: 'EditorialBannerColor';
  /** Color type */
  readonly type?: Maybe<ColorType>;
};

/** Editorial banner content */
export type EditorialBannerContent = {
  readonly __typename?: 'EditorialBannerContent';
  /** Make content collapsable and show ReadMore option */
  readonly showReadMoreOption?: Maybe<Scalars['Boolean']['output']>;
  /** Banner text collection */
  readonly textCollection?: Maybe<ReadonlyArray<Maybe<EditorialBannerText>>>;
  /** Banner title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Banner title color */
  readonly titleColor?: Maybe<EditorialBannerTitleColorEnum>;
  /** Banner title size */
  readonly titleSize?: Maybe<EditorialBannerTitleSizeEnum>;
  /** Content vertical alignment */
  readonly verticalAlignment?: Maybe<VerticalContentAlignment>;
};

/** Image */
export type EditorialBannerImage = {
  readonly __typename?: 'EditorialBannerImage';
  /** Image url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** Editorial banner layout enumeration */
export enum EditorialBannerLayoutEnum {
  /** Banner with four media */
  FourMedia = 'FourMedia',
  /** Banner with headline */
  Headline = 'Headline',
  /** Banner with one media overlayed with text at the bottom */
  OneMediaCenterBottomText = 'OneMediaCenterBottomText',
  /** Banner with one media overlayed with text on the left */
  OneMediaOverlayLeftText = 'OneMediaOverlayLeftText',
  /** Banner with one media overlayed with text on the right */
  OneMediaOverlayRightText = 'OneMediaOverlayRightText',
  /** Banner with one media part and paragraph text */
  OneMediaParagraphText = 'OneMediaParagraphText',
  /** PLP banner with single media */
  OneMediaProductPromo = 'OneMediaProductPromo',
  /** Banner with paragraph text */
  ParagraphText = 'ParagraphText',
  /** Banner with quote */
  Quote = 'Quote',
  /** Banner with three media and right bottom text */
  ThreeMediaRightBottomText = 'ThreeMediaRightBottomText',
  /** Banner with two media parts */
  TwoMedia = 'TwoMedia',
  /** Banner with two media overlayed with text on the top */
  TwoMediaRightTopText = 'TwoMediaRightTopText',
  /** Banner with video */
  Video = 'Video'
}

export type EditorialBannerModule = {
  readonly __typename?: 'EditorialBannerModule';
  /** Banner background color */
  readonly backgroundColor?: Maybe<EditorialBannerColor>;
  /** Banner background image */
  readonly backgroundImage?: Maybe<EditorialBannerImage>;
  /** Banner background type */
  readonly backgroundType?: Maybe<BackgroundType>;
  /** Banner background video */
  readonly backgroundVideo?: Maybe<Video>;
  /** Banner content */
  readonly content?: Maybe<EditorialBannerContent>;
  /** Banner link */
  readonly link?: Maybe<Link>;
};

/** Text wrapper */
export type EditorialBannerText = {
  readonly __typename?: 'EditorialBannerText';
  /** Text content */
  readonly text?: Maybe<Scalars['String']['output']>;
  /** Links represented in text by placeholders ("{0}", "{1}", ...). */
  readonly textLinks?: Maybe<ReadonlyArray<Maybe<Link>>>;
};

/** Editorial banner title color enumeration */
export enum EditorialBannerTitleColorEnum {
  /** The black */
  Black = 'Black',
  /** The dark grey */
  DarkGrey = 'DarkGrey',
  /** The white */
  White = 'White'
}

/** Editorial banner title size enumeration */
export enum EditorialBannerTitleSizeEnum {
  H5 = 'H5',
  H6 = 'H6'
}

/** Representation of a carousel containing editorial page banners */
export type EditorialCarousel = {
  readonly __typename?: 'EditorialCarousel';
  /** Collection of editorial pages shown in carousel */
  readonly editorials?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Carousel ID */
  readonly id: Scalars['ID']['output'];
};

/** Editorial channel type */
export enum EditorialChannelType {
  Mobile = 'Mobile',
  Web = 'Web'
}

/** Editorial header */
export type EditorialHeader = {
  readonly __typename?: 'EditorialHeader';
  /** Name of author */
  readonly authorName?: Maybe<Scalars['String']['output']>;
  /** Background color */
  readonly backgroundStorybookColor?: Maybe<StorybookColor>;
  /** Whether the headline is longer than usual */
  readonly extraLongHeadline?: Maybe<Scalars['Boolean']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Headline text color */
  readonly headlineStorybookColor?: Maybe<StorybookColor>;
  /** Header Id */
  readonly id: Scalars['ID']['output'];
  /** Image url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Indicating if haring is enabled */
  readonly isSharingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Links */
  readonly links?: Maybe<ReadonlyArray<Maybe<Link>>>;
  /** Name of photographer */
  readonly photographerName?: Maybe<Scalars['String']['output']>;
  /** Published date */
  readonly publishedDate?: Maybe<Scalars['Date']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

/** Editorial landing page */
export type EditorialLandingPage = {
  readonly __typename?: 'EditorialLandingPage';
  /**
   * Background color
   * @deprecated Replaced with backgroundStorybookColor
   */
  readonly backgroundColor?: Maybe<EditorialBannerColor>;
  /** Background color */
  readonly backgroundStorybookColor?: Maybe<StorybookColor>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Editorial collection */
  readonly editorialItems?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Headline text color */
  readonly headlineStorybookColor?: Maybe<StorybookColor>;
  /** Image Url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Page Id */
  readonly landingPageId: Scalars['ID']['output'];
  /** Theme collection */
  readonly themeItems?: Maybe<ReadonlyArray<Maybe<EditorialLandingPage>>>;
  /** Theme Tag */
  readonly themeTag?: Maybe<Scalars['String']['output']>;
  /** Thumbnail Url */
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /**
   * Title color
   * @deprecated Replaced with headlineStorybookColor
   */
  readonly titleColor?: Maybe<EditorialBannerTitleColorEnum>;
};

/** Editorial mixed PLP content */
export type EditorialMixedPlpContent = EditorialPlpPromoBanner | Product;

/** Editorial Olapic */
export type EditorialOlapic = {
  readonly __typename?: 'EditorialOlapic';
  /** Category Id */
  readonly categoryId?: Maybe<Scalars['String']['output']>;
  /** Olapic Id */
  readonly id: Scalars['ID']['output'];
  /** Product code */
  readonly product?: Maybe<Product>;
  /** Olapic type */
  readonly type?: Maybe<OlapicTypeEnum>;
};

/** Editorial page */
export type EditorialPage = {
  readonly __typename?: 'EditorialPage';
  /** Content collection */
  readonly contentItems?: Maybe<ReadonlyArray<Maybe<EditorialPageContent>>>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Page Id */
  readonly pageId: Scalars['ID']['output'];
  /** Theme tag */
  readonly themeTag?: Maybe<Scalars['String']['output']>;
  /** Thumbnail URL */
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Editorial page components. */
export type EditorialPageContent = EditorialBanner | EditorialCarousel | EditorialHeader | EditorialOlapic | EditorialPage | EditorialPlpRows | EditorialProductBoxCarousel;

/** Editorial PLP banner */
export type EditorialPlpPromoBanner = {
  readonly __typename?: 'EditorialPlpPromoBanner';
  /** Banner content */
  readonly banner?: Maybe<EditorialBanner>;
  /** Banner size */
  readonly size?: Maybe<EditorialPlpPromoBannerSize>;
};

/** Editorial PLP banner size */
export enum EditorialPlpPromoBannerSize {
  Size1 = 'Size1',
  Size2 = 'Size2'
}

/** Product listing page row */
export type EditorialPlpRow = {
  readonly __typename?: 'EditorialPlpRow';
  /** Row content (products and banners) */
  readonly content?: Maybe<ReadonlyArray<Maybe<EditorialMixedPlpContent>>>;
};

/** Rows composing product listing page */
export type EditorialPlpRows = {
  readonly __typename?: 'EditorialPlpRows';
  /** Facets definition */
  readonly facets?: Maybe<ReadonlyArray<Maybe<PlpFacet>>>;
  /** Product listing pade id */
  readonly id: Scalars['ID']['output'];
  /** Definition of available products ordering options */
  readonly orderByOptions?: Maybe<ReadonlyArray<Maybe<PlpOrderingDefinition>>>;
  /** Collection of rows */
  readonly rows?: Maybe<ReadonlyArray<Maybe<EditorialPlpRow>>>;
  /** Total count of banners */
  readonly totalBannersCount?: Maybe<Scalars['Int']['output']>;
  /** Total count of products */
  readonly totalProductsCount?: Maybe<Scalars['Int']['output']>;
};


/** Rows composing product listing page */
export type EditorialPlpRowsRowsArgs = {
  facetFiltering?: InputMaybe<ReadonlyArray<InputMaybe<EditorialPlpRowsFacetFilter>>>;
  orderBy?: InputMaybe<PlpOrderBy>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  top: Scalars['Int']['input'];
};

/** Facet filter */
export type EditorialPlpRowsFacetFilter = {
  /** Active children keys */
  readonly keys: ReadonlyArray<InputMaybe<Scalars['String']['input']>>;
  /** Facet name */
  readonly name: Scalars['String']['input'];
};

/** Editorial product box carousel */
export type EditorialProductBoxCarousel = {
  readonly __typename?: 'EditorialProductBoxCarousel';
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Products */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

/** Customers email */
export type Email = {
  readonly __typename?: 'Email';
  /** Email address */
  readonly emailAddress?: Maybe<Scalars['String']['output']>;
  /** Is address pending */
  readonly isPending?: Maybe<Scalars['Boolean']['output']>;
};

/** ErrorDialogNewsletterSubsciptionConfiguration */
export type ErrorDialogNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'ErrorDialogNewsletterSubsciptionConfiguration';
  /** Button */
  readonly buttonText?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

/** External Campaing */
export type ExternalCampaign = {
  readonly __typename?: 'ExternalCampaign';
  /** Campaign */
  readonly campaignId?: Maybe<Scalars['String']['output']>;
  /** Campaign Url */
  readonly campaignUrl?: Maybe<Scalars['String']['output']>;
};

/** Feedback benefit banner */
export type FeedbackBenefitBanner = {
  readonly __typename?: 'FeedbackBenefitBanner';
  /** Banner message */
  readonly message?: Maybe<Scalars['String']['output']>;
  /** Link to read more information on benefit */
  readonly readMoreLink?: Maybe<Link>;
  /** Link to register new customer */
  readonly registerLink?: Maybe<Link>;
  /** Link to sign in existing customer */
  readonly signInLink?: Maybe<Link>;
  /** Banner title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Welcome message */
  readonly welcomeMessage?: Maybe<Scalars['String']['output']>;
};

/** Feedback Benefit Banner type */
export enum FeedbackBenefitBannerType {
  Cashback = 'Cashback',
  ShareAndEarn = 'ShareAndEarn'
}

export type Footer = {
  readonly __typename?: 'Footer';
  /** Do not show language switcher pop-up */
  readonly disableLanguageSwitcherPopUp?: Maybe<Scalars['Boolean']['output']>;
  /** Footer Language Configuration */
  readonly languageConfiguration?: Maybe<LanguageConfiguration>;
};

/** FooterNewsletterSubsciptionConfiguration */
export type FooterNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'FooterNewsletterSubsciptionConfiguration';
  /** Enabled */
  readonly enabled?: Maybe<Scalars['Boolean']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type FormattedPrice = {
  readonly __typename?: 'FormattedPrice';
  /** Basic cashback amount */
  readonly basicCashback?: Maybe<Scalars['String']['output']>;
  /** Consultant price from basic catalogue */
  readonly basicCatalogueConsultantPrice?: Maybe<Scalars['String']['output']>;
  /** Price from basic catalogue */
  readonly basicCataloguePrice?: Maybe<Scalars['String']['output']>;
  /** Current consultant price */
  readonly currentConsultantPrice?: Maybe<Scalars['String']['output']>;
  /** Current price */
  readonly currentPrice?: Maybe<Scalars['String']['output']>;
  /** Previous minimum price in last 30 days */
  readonly previousMinOfferPrice?: Maybe<Scalars['String']['output']>;
  /** Price after immediate discount */
  readonly priceAfterImmediateDiscount?: Maybe<Scalars['String']['output']>;
  /** Approximate Share & Earn bonus amount */
  readonly sharingBonus?: Maybe<Scalars['String']['output']>;
};

export type FrontPageImage = {
  readonly __typename?: 'FrontPageImage';
  /** Image's subtitle */
  readonly subTitle?: Maybe<Scalars['String']['output']>;
  /** Image's title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Image's url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

export type Frontpage = {
  readonly __typename?: 'Frontpage';
  /** Beauty Edit section */
  readonly beautyEdit?: Maybe<BeautyEdit>;
  /** Beauty by Sweden Video */
  readonly beautyVideo?: Maybe<BeautyVideo>;
  /** Benefit banner */
  readonly benefitBanner?: Maybe<BenefitBanner>;
  /** Bestseller products section */
  readonly bestsellerProducts?: Maybe<BestsellerProducts>;
  /** Navigation by categories */
  readonly categoryNavigation?: Maybe<ReadonlyArray<Maybe<Link>>>;
  /** Digital promotions */
  readonly digitalPromotions?: Maybe<DigitalPromotions>;
  /** Iconic products section */
  readonly iconicProducts?: Maybe<IconicProducts>;
  /** Motivation content */
  readonly motivationContent?: Maybe<MotivationContent>;
  /** Notification banner */
  readonly notificationBanner?: Maybe<NotificationBanner>;
  /** Product Standards section */
  readonly productStandards?: Maybe<ProductStandards>;
  /** Top Brands section */
  readonly topBrands?: Maybe<TopBrands>;
  /** VIP Benefits section */
  readonly vipBenefits?: Maybe<VipBenefits>;
};


export type FrontpageDigitalPromotionsArgs = {
  channel: ChannelType;
  validityDate?: InputMaybe<Scalars['Date']['input']>;
};

/** Enum gender */
export enum Gender {
  Man = 'Man',
  Other = 'Other',
  Woman = 'Woman'
}

export type Genesys = {
  readonly __typename?: 'Genesys';
  /** Genesys configuration */
  readonly configuration?: Maybe<GenesysConfiguration>;
  /** Determines whether mobile of Genesys widget version is enabled */
  readonly isMobileWidgetEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether predictive engagement feature is enabled */
  readonly isPredictiveFeatureEnabled?: Maybe<Scalars['Boolean']['output']>;
};

export type GenesysConfiguration = {
  readonly __typename?: 'GenesysConfiguration';
  /** ButtonLabel */
  readonly buttonLabel?: Maybe<Scalars['String']['output']>;
  /** Data Url */
  readonly dataUrl?: Maybe<Scalars['String']['output']>;
  /** Deployment Id */
  readonly deploymentId?: Maybe<Scalars['String']['output']>;
  /** Organization Id */
  readonly organizationId?: Maybe<Scalars['String']['output']>;
  /** Plugins path */
  readonly pluginsPath?: Maybe<Scalars['String']['output']>;
  /** Queue */
  readonly queue?: Maybe<Scalars['String']['output']>;
  /** Script Url */
  readonly scriptUrl?: Maybe<Scalars['String']['output']>;
};

export type HeroBanner = {
  readonly __typename?: 'HeroBanner';
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Priority */
  readonly priority?: Maybe<Scalars['Int']['output']>;
  /** Small Image url of the banner */
  readonly smallImageUrl?: Maybe<Scalars['String']['output']>;
  /** Tagline of the banner */
  readonly tagline?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['Date']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['Date']['output']>;
  /** Video url */
  readonly videoUrl?: Maybe<Scalars['String']['output']>;
};

/** Iconic products */
export type IconicProducts = {
  readonly __typename?: 'IconicProducts';
  /** Banner of the iconic products. */
  readonly banner?: Maybe<ProductsPromotionBanner>;
  /** Description of the iconic products. */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Headline of the iconic products. */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** List of iconic products. */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
};

export type Ingredient = {
  readonly __typename?: 'Ingredient';
  /** Summary of benefits */
  readonly benefitSummary?: Maybe<Scalars['String']['output']>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Did you know label */
  readonly didYouKnow?: Maybe<Scalars['String']['output']>;
  /** Url to the image */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Key benefit 1 */
  readonly keyBenefit1?: Maybe<Scalars['String']['output']>;
  /** Key benefit 2 */
  readonly keyBenefit2?: Maybe<Scalars['String']['output']>;
  /** Key benefit 3 */
  readonly keyBenefit3?: Maybe<Scalars['String']['output']>;
  /** Key benefits */
  readonly keyBenefits?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Latin name */
  readonly latinName?: Maybe<Scalars['String']['output']>;
  /** Link to ingredient detail */
  readonly link?: Maybe<Link>;
  /** Total number  of ingredient products */
  readonly numberOfProducts?: Maybe<Scalars['Int']['output']>;
  /** Collection of products */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Related articles */
  readonly relatedArticles?: Maybe<ReadonlyArray<Maybe<Article>>>;
  /** Short name */
  readonly shortName?: Maybe<Scalars['String']['output']>;
};


export type IngredientProductsArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type IngredientRelatedArticlesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};

export type IngredientLibrary = {
  readonly __typename?: 'IngredientLibrary';
  /** Ingredient library inspiration */
  readonly inspiration?: Maybe<IngredientLibraryInspiration>;
};

export type IngredientLibraryInspiration = {
  readonly __typename?: 'IngredientLibraryInspiration';
  /** Ingredient library inspiration text */
  readonly inspirationTexts?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
};

export type InviteFriendProfile = {
  readonly __typename?: 'InviteFriendProfile';
  /** Consultant number */
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  /** Consultant contacts */
  readonly contacts?: Maybe<ReadonlyArray<Maybe<SocialContact>>>;
  /** Consultant profile description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Consultant first name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** Consultant last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** Consultant prfile photo url */
  readonly photoUrl?: Maybe<Scalars['String']['output']>;
};

export type IsPrimaryMetadataItem = {
  readonly __typename?: 'IsPrimaryMetadataItem';
  /** Order of property in which should be preferably displayed (e.g. field on edit form) */
  readonly displayOrder?: Maybe<Scalars['Int']['output']>;
  /** Is item available */
  readonly isAvailable?: Maybe<Scalars['Boolean']['output']>;
  /** Is item editable */
  readonly isEditable?: Maybe<Scalars['Boolean']['output']>;
};

/** Language type */
export type Language = {
  readonly __typename?: 'Language';
  /** Language code */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** Language name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Language type */
  readonly type?: Maybe<LanguageType>;
};

/** Language configuration type */
export type LanguageConfiguration = {
  readonly __typename?: 'LanguageConfiguration';
  /** Country label */
  readonly countryLabel?: Maybe<Scalars['String']['output']>;
  /** Current Country */
  readonly currentCountry?: Maybe<Scalars['String']['output']>;
  /** Language label */
  readonly languageLabel?: Maybe<Scalars['String']['output']>;
  /** Select country label */
  readonly selectCountryLabel?: Maybe<Scalars['String']['output']>;
  /** Sekect country URL */
  readonly selectCountryUrl?: Maybe<Scalars['String']['output']>;
};

/** Enum language type */
export enum LanguageType {
  Alternative = 'Alternative',
  Default = 'Default'
}

export type Link = {
  readonly __typename?: 'Link';
  /** AppLink */
  readonly appLink?: Maybe<AppLink>;
  /** Data related to the link */
  readonly data?: Maybe<LinkData>;
  /** Dynamic url for sharing */
  readonly dynamicSharingUrl?: Maybe<Scalars['String']['output']>;
  /** Relative url */
  readonly relativeUrl?: Maybe<Scalars['String']['output']>;
  /** Link's title */
  readonly title?: Maybe<Scalars['String']['output']>;
};


export type LinkDynamicSharingUrlArgs = {
  storeName?: InputMaybe<Scalars['ID']['input']>;
};

/** Data associated with a link */
export type LinkData = ArticleContent | Brand | Catalogue | Category | EditorialPage | ExternalCampaign | NewProduct | NovageSkincareAdvisor | Product | ProductListingPage;

/** Makeup Try On */
export type MakeupTryOnConfiguration = {
  readonly __typename?: 'MakeupTryOnConfiguration';
  /** product Detail */
  readonly productDetail?: Maybe<ProductDetailMakeupTryOnConfiguration>;
};

export type MakeupTryOnProductRelation = {
  readonly __typename?: 'MakeupTryOnProductRelation';
  /** Related Shi Shi Product Id */
  readonly shiShiProductId?: Maybe<Scalars['String']['output']>;
};

export type Manufacturer = {
  readonly __typename?: 'Manufacturer';
  /** Manufacturer address */
  readonly address?: Maybe<ManufacturerAddress>;
  /** Manufacturer Id */
  readonly manufacturerId?: Maybe<Scalars['String']['output']>;
  /** Manufacturer name */
  readonly name?: Maybe<Scalars['String']['output']>;
};

export type ManufacturerAddress = {
  readonly __typename?: 'ManufacturerAddress';
  /** Address 1 */
  readonly address1?: Maybe<Scalars['String']['output']>;
  /** Address 2 */
  readonly address2?: Maybe<Scalars['String']['output']>;
  /** City */
  readonly city?: Maybe<Scalars['String']['output']>;
  /** Country code */
  readonly countryCode?: Maybe<Scalars['String']['output']>;
  /** Country name */
  readonly countryName?: Maybe<Scalars['String']['output']>;
  /** State */
  readonly state?: Maybe<Scalars['String']['output']>;
  /** Zip code */
  readonly zipCode?: Maybe<Scalars['String']['output']>;
};

/** Operations */
export type MetadataOperations = {
  readonly __typename?: 'MetadataOperations';
  /** Create */
  readonly create?: Maybe<AddressMetadataOperation>;
  /** Delete */
  readonly delete?: Maybe<AddressMetadataOperation>;
  /** Read */
  readonly read?: Maybe<AddressMetadataOperation>;
  /** Update */
  readonly update?: Maybe<AddressMetadataOperation>;
};

/** Mixed Plp Content */
export type MixedPlpContent = Product;

/** Mobile App */
export type MobileApp = {
  readonly __typename?: 'MobileApp';
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Urls of additional images */
  readonly images?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Links related to the mobile app */
  readonly links?: Maybe<MobileAppLinks>;
  /** Type of the mobile app */
  readonly mobileAppType?: Maybe<MobileAppTypeData>;
  /** Subtitle */
  readonly subtitle?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Url of the main image */
  readonly titleImage?: Maybe<Scalars['String']['output']>;
};

/** Link of the mobile app */
export type MobileAppLinks = {
  readonly __typename?: 'MobileAppLinks';
  /** Link of the mobile app in Apple Store */
  readonly appleStoreLink?: Maybe<Scalars['String']['output']>;
  /** Link of the mobile app in Google Play Store */
  readonly googlePlayStoreLink?: Maybe<Scalars['String']['output']>;
};

export enum MobileAppType {
  None = 'None',
  ToSell = 'ToSell',
  ToShop = 'ToShop'
}

/** Type of mobile app */
export type MobileAppTypeData = {
  readonly __typename?: 'MobileAppTypeData';
  /** Title of the type */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Type of the mobile app */
  readonly type?: Maybe<MobileAppType>;
};

/** Mobile apps */
export type MobileApps = {
  readonly __typename?: 'MobileApps';
  /** Mobile apps */
  readonly mobileAppCollection?: Maybe<ReadonlyArray<Maybe<MobileApp>>>;
  /** Header of mobile apps page */
  readonly pageHeader?: Maybe<MobileAppsPageHeader>;
};

/** Header of the mobile apps page */
export type MobileAppsPageHeader = {
  readonly __typename?: 'MobileAppsPageHeader';
  /** Apple Store image */
  readonly appleStoreImage?: Maybe<Scalars['String']['output']>;
  /** Google Play image */
  readonly googlePlayImage?: Maybe<Scalars['String']['output']>;
  /** Subtitle */
  readonly subtitle?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type MotivationContent = {
  readonly __typename?: 'MotivationContent';
  /** Section's headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Text displayed in the section */
  readonly text?: Maybe<Scalars['String']['output']>;
};

/** Mutation root */
export type Mutation = {
  readonly __typename?: 'Mutation';
  /** Subscribe to notification on current logged in customer */
  readonly backInStockNotificationSubscription?: Maybe<Scalars['Void']['output']>;
  /** Update share and earn personal code */
  readonly shareAndEarnPersonalCodeUpdate?: Maybe<MutationResult>;
};


/** Mutation root */
export type MutationBackInStockNotificationSubscriptionArgs = {
  input: BackInStockNotificationSubscriptionInput;
};


/** Mutation root */
export type MutationShareAndEarnPersonalCodeUpdateArgs = {
  input: ShareAndEarnUpdatePersonalCodeInput;
};

/** Mutation result */
export type MutationResult = {
  readonly __typename?: 'MutationResult';
  /** Error message */
  readonly message?: Maybe<Scalars['String']['output']>;
  /** Indicates whether the mutation was successful or not */
  readonly success?: Maybe<Scalars['Boolean']['output']>;
};

/** Navigation Item */
export type Navigation = {
  readonly __typename?: 'Navigation';
  /** Collection of subitems */
  readonly children?: Maybe<ReadonlyArray<Maybe<Navigation>>>;
  /** Description of the item */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Id of the navigation item */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Image of the item */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Type of the navigation link */
  readonly linkType?: Maybe<NavigationLinkType>;
  /** Specifies nodeId in tree structure */
  readonly nodeId?: Maybe<Scalars['String']['output']>;
  /** Title of the navigation item */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Url of the item */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** Collection of navigation items */
export type NavigationCollection = {
  readonly __typename?: 'NavigationCollection';
  /** Collection of Navigation items */
  readonly children?: Maybe<ReadonlyArray<Maybe<Navigation>>>;
  /** Navigation type */
  readonly navigationType?: Maybe<NavigationType>;
};

/** Possible types of Navigation items */
export enum NavigationLinkType {
  /** All category */
  AllCategory = 'AllCategory',
  /** Beauty Tips section */
  BeautyTips = 'BeautyTips',
  /** Bestseller products page */
  Bestsellers = 'Bestsellers',
  /** Bestseller products in category */
  BestsellersInCategory = 'BestsellersInCategory',
  /** Bottom part of the footer */
  Bottom = 'Bottom',
  /** Bottom part of the footer with copyright info */
  BottomCopyright = 'BottomCopyright',
  /** Links from the bottom part of the footer */
  BottomLinks = 'BottomLinks',
  /** Catalogue page */
  Catalogue = 'Catalogue',
  /** Catalogues page */
  Catalogues = 'Catalogues',
  /** Category */
  Category = 'Category',
  /** Catalogue for the current period */
  DigitalCatalogueCurrent = 'DigitalCatalogueCurrent',
  /** Catalogue for the next period */
  DigitalCatalogueNext = 'DigitalCatalogueNext',
  /** Focus page */
  FocusPage = 'FocusPage',
  /** Column of a footer */
  FooterColumn = 'FooterColumn',
  /** Item placed in footer's column */
  FooterColumnItem = 'FooterColumnItem',
  /** I Don't know yet link */
  IDontKnowYet = 'IDontKnowYet',
  /** Iconic products page */
  Icons = 'Icons',
  /** Iconic products in category */
  IconsInCategory = 'IconsInCategory',
  /** Inspiration section */
  Inspiration = 'Inspiration',
  /** Join rewards */
  JoinRewards = 'JoinRewards',
  /** Key ingredients page */
  KeyIngredients = 'KeyIngredients',
  /** Menu tiles */
  MenuTiles = 'MenuTiles',
  /** New products */
  New = 'New',
  /** No link type was specified for this item */
  None = 'None',
  /** Our applications */
  OurApps = 'OurApps',
  /** Outlet shop */
  OutletShop = 'OutletShop',
  /** Payment method */
  PaymentMethod = 'PaymentMethod',
  /** Available payment methods */
  PaymentMethods = 'PaymentMethods',
  /** Popular brand */
  PopularBrand = 'PopularBrand',
  /** Popular brands */
  PopularBrands = 'PopularBrands',
  /** Popular lookbook */
  PopularLookbook = 'PopularLookbook',
  /** Turkish QR code */
  Qrtr = 'QRTR',
  /** Rewards shop page */
  RewardsShop = 'RewardsShop',
  /** Sample shop page */
  SampleShop = 'SampleShop',
  /** Segment of subcategory */
  Segment = 'Segment',
  /** Subcategory */
  SubCategory = 'SubCategory',
  /** Support center */
  SupportCenter = 'SupportCenter',
  /** Top navigation */
  TopNavigation = 'TopNavigation',
  /** What's new in the category */
  WhatsNewInCategory = 'WhatsNewInCategory'
}

/** Navigationag type definition */
export type NavigationTag = {
  readonly __typename?: 'NavigationTag';
  /** NavigationTag title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** NavigationTag url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** Possible types of navigations */
export enum NavigationType {
  /** About tiles section content */
  AboutTiles = 'AboutTiles',
  /** Footer section */
  Footer = 'Footer',
  /** Inspiration tiles content */
  InspirationTiles = 'InspirationTiles',
  /** Join section content */
  JoinTiles = 'JoinTiles',
  /** Shop section content */
  ShopSection = 'ShopSection',
  /** Top navigation bar items */
  TopNavigation = 'TopNavigation'
}

export type NewProduct = {
  readonly __typename?: 'NewProduct';
  /** Placeholder. This type has yet to be implemented. */
  readonly placeholder?: Maybe<Scalars['String']['output']>;
};

/** Newsletter Subsciption Configuration Type */
export type NewsletterSubsciptionConfiguration = {
  readonly __typename?: 'NewsletterSubsciptionConfiguration';
  /** Error Dialog */
  readonly errorDialog?: Maybe<ErrorDialogNewsletterSubsciptionConfiguration>;
  /** Footer */
  readonly footer?: Maybe<FooterNewsletterSubsciptionConfiguration>;
  /** Popup */
  readonly popup?: Maybe<PopupNewsletterSubsciptionConfiguration>;
  /** Response Messages */
  readonly responseMessages?: Maybe<ReadonlyArray<Maybe<ResponseMessageNewsletterSubsciptionConfiguration>>>;
  /** Submit Dialog */
  readonly submitDialog?: Maybe<SubmitDialogNewsletterSubsciptionConfiguration>;
  /** Subscribed Dialog */
  readonly subscribedDialog?: Maybe<SubscribedDialogNewsletterSubsciptionConfiguration>;
};

export type NotificationBanner = {
  readonly __typename?: 'NotificationBanner';
  /** Link title */
  readonly messageLinkTitle?: Maybe<Scalars['String']['output']>;
  /** Link URL */
  readonly messageLinkUrl?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly messageText?: Maybe<Scalars['String']['output']>;
  /** Theme */
  readonly messageTheme?: Maybe<NotificationBannerThemeType>;
};

export enum NotificationBannerThemeType {
  Black = 'Black',
  Grey = 'Grey'
}

export type NovageSkincareAdvisor = {
  readonly __typename?: 'NovageSkincareAdvisor';
  /** environment */
  readonly environment?: Maybe<Scalars['String']['output']>;
  /** Partner id */
  readonly partnerId?: Maybe<Scalars['String']['output']>;
};

/** Olapic configuration */
export type OlapicConfiguration = {
  readonly __typename?: 'OlapicConfiguration';
  /** Olapic api key */
  readonly apiKey?: Maybe<Scalars['String']['output']>;
  /** Related category */
  readonly category?: Maybe<Category>;
  /** Hide caption toggle */
  readonly hideCaption?: Maybe<Scalars['Boolean']['output']>;
  /** olapic id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Olapic instance */
  readonly instance?: Maybe<Scalars['String']['output']>;
  /** Olapic item name */
  readonly itemName?: Maybe<Scalars['String']['output']>;
  /** language code */
  readonly languageCode?: Maybe<Scalars['String']['output']>;
  /** Related product */
  readonly product?: Maybe<Product>;
  /** Olapic script url */
  readonly scriptUrl?: Maybe<Scalars['String']['output']>;
  /** Olapic widget type */
  readonly type?: Maybe<OlapicTypeEnum>;
};

/** Olapic type enumeration */
export enum OlapicTypeEnum {
  /** Category widget */
  Category = 'Category',
  /** Front page widget */
  FrontPage = 'FrontPage',
  /** Gallery widget */
  Gallery = 'Gallery',
  /** Product detail page widget */
  ProductDetails = 'ProductDetails'
}

/** Navigation menu for a single page */
export type PageNavigation = {
  readonly __typename?: 'PageNavigation';
  /** Navigation menu item */
  readonly items?: Maybe<ReadonlyArray<Maybe<PageNavigationItem>>>;
  /** Page ID */
  readonly pageId?: Maybe<Scalars['String']['output']>;
};

/** One item of page navigation menu */
export type PageNavigationItem = {
  readonly __typename?: 'PageNavigationItem';
  /** Unique ID of navigation item */
  readonly itemId?: Maybe<Scalars['String']['output']>;
  /** Localized label */
  readonly label?: Maybe<Scalars['String']['output']>;
  /** Relative URL */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** Personal beauty store details */
export type PersonalBeautyStore = {
  readonly __typename?: 'PersonalBeautyStore';
  /** Text description of PBS / applicable for PBS lite */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Name of PBS */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Product lists */
  readonly productLists?: Maybe<ReadonlyArray<Maybe<ProductList>>>;
  /** Personal beauty store owner profile */
  readonly profile?: Maybe<PersonalBeautyStoreProfile>;
  /** Url to PBS */
  readonly url?: Maybe<Scalars['String']['output']>;
  /** Live video shows */
  readonly videoShows?: Maybe<ReadonlyArray<Maybe<VideoShow>>>;
};


/** Personal beauty store details */
export type PersonalBeautyStoreProductListsArgs = {
  productListType: ProductListType;
};

/** Personal beauty store profile */
export type PersonalBeautyStoreProfile = {
  readonly __typename?: 'PersonalBeautyStoreProfile';
  /** Consultant number */
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  /** Pbs owner contacts */
  readonly contacts?: Maybe<ReadonlyArray<Maybe<SocialContact>>>;
  /** Description of PBS */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** First name */
  readonly firstName?: Maybe<Scalars['String']['output']>;
  /** Last name */
  readonly lastName?: Maybe<Scalars['String']['output']>;
  /** Name PBS store */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Pbs owner photo */
  readonly photoUrl?: Maybe<Scalars['String']['output']>;
  /** Referral program data */
  readonly referralProgram?: Maybe<ReferralProgram>;
};

/** Personal product list configuration */
export type PersonalProductListConfiguration = {
  readonly __typename?: 'PersonalProductListConfiguration';
  /** Add This social network sharer */
  readonly addThis?: Maybe<Scalars['JSON']['output']>;
  /** Link to a home page */
  readonly homePageUrl?: Maybe<Scalars['String']['output']>;
  /** Is dynamic link enabled for PPL */
  readonly isDynamicLinkEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is PBS ownership is required for PPL */
  readonly isPbsRequired?: Maybe<Scalars['Boolean']['output']>;
  /** Is PPL enabled */
  readonly isPplEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Link to Personal Beauty Store management page */
  readonly pbsPageUrl?: Maybe<Scalars['String']['output']>;
};

/** Customers phone */
export type Phone = {
  readonly __typename?: 'Phone';
  /** Is telephone number primary */
  readonly isPrimary?: Maybe<Scalars['Boolean']['output']>;
  /** Is telephone number verified */
  readonly isVerified?: Maybe<Scalars['Boolean']['output']>;
  /** Telephone id */
  readonly telephoneId: Scalars['ID']['output'];
  /** Telephone number */
  readonly telephoneNumber?: Maybe<Scalars['String']['output']>;
  /** Telephone type */
  readonly telephoneType?: Maybe<TelephoneType>;
};

/** PLP facet defininition */
export type PlpFacet = {
  readonly __typename?: 'PlpFacet';
  /** Facet is active for products filtering */
  readonly active?: Maybe<Scalars['Boolean']['output']>;
  /** Facet display name */
  readonly displayName?: Maybe<Scalars['String']['output']>;
  /** Facet name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Available facet values */
  readonly values?: Maybe<ReadonlyArray<Maybe<PlpFacetValue>>>;
};

/** Facet info */
export type PlpFacetInfo = {
  readonly __typename?: 'PlpFacetInfo';
  /** Color hex code (for color-family facet field) */
  readonly color?: Maybe<Scalars['String']['output']>;
};

/** PLP facet value */
export type PlpFacetValue = {
  readonly __typename?: 'PlpFacetValue';
  /** Facet value is active for products filtering */
  readonly active?: Maybe<Scalars['Boolean']['output']>;
  /** Count of products meeting facet condition */
  readonly count?: Maybe<Scalars['Int']['output']>;
  /** Value display name */
  readonly displayName?: Maybe<Scalars['String']['output']>;
  /** Facet info */
  readonly facetInfo?: Maybe<PlpFacetInfo>;
  /** Value key */
  readonly key?: Maybe<Scalars['String']['output']>;
};

/** Products order strategies */
export enum PlpOrderBy {
  HighestDiscount = 'HighestDiscount',
  HighestPrice = 'HighestPrice',
  HighestRating = 'HighestRating',
  Latest = 'Latest',
  LowestPrice = 'LowestPrice',
  Recommended = 'Recommended'
}

/** Definition of possible products ordering strategy */
export type PlpOrderingDefinition = {
  readonly __typename?: 'PlpOrderingDefinition';
  /** Display text */
  readonly displayText?: Maybe<Scalars['String']['output']>;
  /** Product ordering strategy */
  readonly orderBy?: Maybe<PlpOrderBy>;
};

/** PLP row */
export type PlpRow = {
  readonly __typename?: 'PlpRow';
  /** Content */
  readonly content?: Maybe<ReadonlyArray<Maybe<MixedPlpContent>>>;
};

/** Plp rows facet filter input */
export type PlpRowsFacetFilterInput = {
  /** Gets or sets the active children keys. */
  readonly keys?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  /** Gets or sets the facet name. */
  readonly name?: InputMaybe<Scalars['String']['input']>;
};

/** PopupNewsletterSubsciptionConfiguration */
export type PopupNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'PopupNewsletterSubsciptionConfiguration';
  /** DelayInSeconds */
  readonly delayInSeconds?: Maybe<Scalars['Int']['output']>;
  /** DisplayFrequencyInDays */
  readonly displayFrequencyInDays?: Maybe<Scalars['Int']['output']>;
  /** Enabled */
  readonly enabled?: Maybe<Scalars['Boolean']['output']>;
};

export type Price = {
  readonly __typename?: 'Price';
  /** Basic cashback amount */
  readonly basicCashback?: Maybe<Scalars['Float']['output']>;
  /** Consultant price from basic catalogue */
  readonly basicCatalogueConsultantPrice?: Maybe<Scalars['Float']['output']>;
  /** Price from basic catalogue */
  readonly basicCataloguePrice?: Maybe<Scalars['Float']['output']>;
  /** Current consultant price */
  readonly currentConsultantPrice?: Maybe<Scalars['Float']['output']>;
  /** Current price */
  readonly currentPrice?: Maybe<Scalars['Float']['output']>;
  /** Previous minimum price in last 30 days */
  readonly previousMinOfferPrice?: Maybe<Scalars['Float']['output']>;
  /** Price after immediate discount */
  readonly priceAfterImmediateDiscount?: Maybe<Scalars['Float']['output']>;
  /** Approximate Share & Earn bonus amount */
  readonly sharingBonus?: Maybe<Scalars['Float']['output']>;
};

export type Product = {
  readonly __typename?: 'Product';
  /** Accord Dangereux Routier (Classification of Dangerous Goods). */
  readonly adr?: Maybe<Scalars['String']['output']>;
  /** Collection of alternative images */
  readonly alternativeImageUrls?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Product availability flags */
  readonly availability?: Maybe<ProductAvailability>;
  /** Object indicating that back in stock notification is available */
  readonly backInStockAvailability?: Maybe<BackInStockAvailability>;
  /** API call with benefitItemCode */
  readonly benefitCatalogueOffers?: Maybe<ReadonlyArray<Maybe<CatalogueOffer>>>;
  /** Priority of Best Seller product. */
  readonly bestsellerPriority?: Maybe<Scalars['Int']['output']>;
  /** Bundle Components */
  readonly bundleComponents?: Maybe<ReadonlyArray<Maybe<BundleComponent>>>;
  /** Collection of catalogue offers. API call with benefitItemCode and purchaseItemCode */
  readonly catalogueOffers?: Maybe<ReadonlyArray<Maybe<CatalogueOffer>>>;
  /**
   * Color name
   * @deprecated Use 'shadeName' field instead
   */
  readonly color?: Maybe<Scalars['String']['output']>;
  /**
   * Color HEX code
   * @deprecated Use 'hexColors' field instead
   */
  readonly colorHexCode?: Maybe<Scalars['String']['output']>;
  /** Color url */
  readonly colorImageUrl?: Maybe<Scalars['String']['output']>;
  /** Concept */
  readonly concept?: Maybe<Concept>;
  /**
   * Dimensions of accessory product
   * @deprecated Use 'dimensionsText' field instead
   */
  readonly dimension?: Maybe<Scalars['String']['output']>;
  /** Localized dimensions of product (including value and units). */
  readonly dimensionsText?: Maybe<Scalars['String']['output']>;
  /** Product discontinuation date (product no more manufactured). */
  readonly discoDate?: Maybe<Scalars['Date']['output']>;
  /** Euro monitor class ID. */
  readonly euroMonitorClassId?: Maybe<Scalars['String']['output']>;
  /** Formatted price and BP of a product */
  readonly formattedPrice?: Maybe<ProductFormattedPrice>;
  /** Global Commercial Intent (gcint). */
  readonly globalCommercialIntent?: Maybe<Scalars['String']['output']>;
  /** Hash code calculated form the relevant product information */
  readonly hashCode?: Maybe<Scalars['Int']['output']>;
  /** Collection of product color hexadecimal codes. */
  readonly hexColors?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Product Image url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Comma separated list of product ingredients. */
  readonly inci?: Maybe<Scalars['String']['output']>;
  /** Bundles in which product is included */
  readonly includedInBundles?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Ingredients */
  readonly ingredients?: Maybe<ReadonlyArray<Maybe<Ingredient>>>;
  /** Product introduction date (before the date, the product is not available). */
  readonly introDate?: Maybe<Scalars['Date']['output']>;
  /** Flag indicating if product is Best Seller. */
  readonly isBestseller?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the product is discontinued */
  readonly isDiscontinued?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the product is a outlet. True - outlet product. False - product is not a outlet */
  readonly isOutlet?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating if product is customized. */
  readonly isProductCustomized?: Maybe<Scalars['Boolean']['output']>;
  /** Flag indicating whether the product is a sample. True - sample product. False - product is not a sample */
  readonly isSample?: Maybe<Scalars['Boolean']['output']>;
  /** List of product labels which have true value */
  readonly labels?: Maybe<ReadonlyArray<Maybe<ProductLabel>>>;
  /** Makeup Try On Product Relation */
  readonly makeupTryOn?: Maybe<MakeupTryOnProductRelation>;
  /** Manufacturers */
  readonly manufacturers?: Maybe<ReadonlyArray<Maybe<Manufacturer>>>;
  /** Collection of parent products */
  readonly parentProducts?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Product detail page url. Only relativeUrl field is available. */
  readonly pdpUrl?: Maybe<Link>;
  /** Permission flags like CanDisplay */
  readonly permission?: Maybe<ProductPermission>;
  /** Product price and BP */
  readonly price?: Maybe<ProductPrice>;
  /** Code that identifies the resulting product */
  readonly productCode: Scalars['ID']['output'];
  /** Product Expected Inbound */
  readonly productExpectedInbound?: Maybe<ProductExpectedInbound>;
  /** Type of the product = ['Product', 'Bundle', 'Sample', 'Fee', 'Composite'] */
  readonly productType?: Maybe<ProductType>;
  /** API call with purchaseItemCode */
  readonly purchaseCatalogueOffers?: Maybe<ReadonlyArray<Maybe<CatalogueOffer>>>;
  /** Recommended products */
  readonly recommendations?: Maybe<ReadonlyArray<Maybe<ProductRecommendation>>>;
  /** Collection of related products */
  readonly relations?: Maybe<ReadonlyArray<Maybe<ProductRelation>>>;
  /** Replacement product for this product. */
  readonly replacedByProduct?: Maybe<Product>;
  /** Product that will be replaced by this product. */
  readonly replacementForProduct?: Maybe<Product>;
  /** Product replacements */
  readonly replacements?: Maybe<ReadonlyArray<Maybe<ProductReplacement>>>;
  /** Sample product for current product */
  readonly sample?: Maybe<Product>;
  /** Localized product shade/color name. */
  readonly shadeName?: Maybe<Scalars['String']['output']>;
  /** Flag indicating if product should be shown on site */
  readonly showOnSite?: Maybe<Scalars['Boolean']['output']>;
  /** Subscription configuration for this product */
  readonly subscriptionBenefitBanner?: Maybe<SubscriptionBenefitBanner>;
  /** Collection of product tags */
  readonly tags?: Maybe<ReadonlyArray<Maybe<Tag>>>;
  /** Thumbnail image url */
  readonly thumbnailUrl?: Maybe<Scalars['String']['output']>;
  /** Product video url */
  readonly videoUrl?: Maybe<Scalars['String']['output']>;
  /** Product volume value. */
  readonly volume?: Maybe<Scalars['Float']['output']>;
  /**
   * Localized value of product volume.
   * @deprecated Use 'volumeValue' field instead
   */
  readonly volumeText?: Maybe<Scalars['String']['output']>;
  /**
   * Volume unit
   * @deprecated Use 'volumeUnitText' field instead
   */
  readonly volumeUnit?: Maybe<Scalars['String']['output']>;
  /** Localized unit of product volume. */
  readonly volumeUnitText?: Maybe<Scalars['String']['output']>;
  /** Localized value of product volume. */
  readonly volumeValue?: Maybe<Scalars['Float']['output']>;
};


export type ProductBenefitCatalogueOffersArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductCatalogueOffersArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductProductExpectedInboundArgs = {
  atpClusterId?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductPurchaseCatalogueOffersArgs = {
  top?: InputMaybe<Scalars['Int']['input']>;
};


export type ProductRelationsArgs = {
  productRelations?: InputMaybe<ReadonlyArray<InputMaybe<ProductRelationEnum>>>;
};

export type ProductAvailability = {
  readonly __typename?: 'ProductAvailability';
  /** Expected Inbound */
  readonly expectedInbound?: Maybe<Scalars['Date']['output']>;
  /** When item is out of stock, and can be backordered */
  readonly isBackorder?: Maybe<Scalars['Boolean']['output']>;
  /** Is bundle */
  readonly isBundle?: Maybe<Scalars['Boolean']['output']>;
  /** Introduction date of product is close */
  readonly isComingSoon?: Maybe<Scalars['Boolean']['output']>;
  /** Specified by number of days (sitecore) before disco date */
  readonly isLastChance?: Maybe<Scalars['Boolean']['output']>;
  /** Current ATP is lower than 24hr sales history */
  readonly isLowStock?: Maybe<Scalars['Boolean']['output']>;
  /** Number of days (sitecore) after intro date */
  readonly isNew?: Maybe<Scalars['Boolean']['output']>;
  /** Product is discontinued */
  readonly isNoLongerForSale?: Maybe<Scalars['Boolean']['output']>;
  /** Product cant be sold, price = 0, or is deleted */
  readonly isNotSoldOnline?: Maybe<Scalars['Boolean']['output']>;
  /** Is not standard item, catalogue offer for specific period */
  readonly isOffer?: Maybe<Scalars['Boolean']['output']>;
  /** Out of stock */
  readonly isOutOfStock?: Maybe<Scalars['Boolean']['output']>;
  /** Is sales limited */
  readonly isSalesLimited?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Out of stock and discontinued date is older than today
   * @deprecated Label discontinued
   */
  readonly isSoldOut?: Maybe<Scalars['Boolean']['output']>;
  /** Product is Super Deal */
  readonly isSuperDeal?: Maybe<Scalars['Boolean']['output']>;
  /**
   * Not out of stock, today is newer than discontinued date, or intro and disco date are from each other more than configurable value (sitecore)
   * @deprecated Label discontinued
   */
  readonly isUntilStockLasts?: Maybe<Scalars['Boolean']['output']>;
  /** Replacements */
  readonly replacements?: Maybe<ReadonlyArray<Maybe<Replacement>>>;
};

export type ProductDetailMakeupTryOnConfiguration = {
  readonly __typename?: 'ProductDetailMakeupTryOnConfiguration';
  /** allows Live Camera Label */
  readonly allowsLiveCameraLabel?: Maybe<Scalars['String']['output']>;
  /** Enabled */
  readonly enabled?: Maybe<Scalars['Boolean']['output']>;
  /** Shi Shi Component Url */
  readonly shiShiComponentUrl?: Maybe<Scalars['String']['output']>;
  /** try It On Label */
  readonly tryItOnLabel?: Maybe<Scalars['String']['output']>;
};

export type ProductDetailPage = {
  readonly __typename?: 'ProductDetailPage';
  /** Breadcrumb links */
  readonly breadcrumbLinks?: Maybe<BreadcrumbLinks>;
  /** PDP configuration */
  readonly configuration?: Maybe<ProductDetailPageConfiguration>;
  /** Editorial carousel */
  readonly editorialCarousel?: Maybe<EditorialCarousel>;
  /** Product */
  readonly product?: Maybe<Product>;
  /** Tooling banners */
  readonly toolingBanners?: Maybe<ReadonlyArray<Maybe<ProductToolingBanner>>>;
};

/** Product detail page configuration */
export type ProductDetailPageConfiguration = {
  readonly __typename?: 'ProductDetailPageConfiguration';
  /** Is MRP statement enabled */
  readonly isMrpStatementEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is sharing enabled */
  readonly isSharingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Max quantity */
  readonly maxQuantity?: Maybe<Scalars['Int']['output']>;
  /** Product detail page sections */
  readonly sections?: Maybe<ProductDetailPageSections>;
  /** Shipping info */
  readonly shippingInfo?: Maybe<ProductDetailShippingInfo>;
};

/** Product Detail Page Sections */
export type ProductDetailPageSections = {
  readonly __typename?: 'ProductDetailPageSections';
  /** Indicates if the about section is disabled */
  readonly aboutDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the additional information section is disabled */
  readonly additionalInformationDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the beauty edit module is disabled */
  readonly beautyEditModuleDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the how to use section is disabled */
  readonly howToUseDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the ingredients section is disabled */
  readonly ingredientsDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Inducates if the rating and reviews section is disabled */
  readonly ratingAndReviewsDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates if the shipping info section is disabled */
  readonly shippingInfoDisabled?: Maybe<Scalars['Boolean']['output']>;
};

/** Product detail shipping info */
export type ProductDetailShippingInfo = {
  readonly __typename?: 'ProductDetailShippingInfo';
  /** Shipping info content */
  readonly content?: Maybe<Scalars['String']['output']>;
  /** Shipping info title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Product expected Inbound */
export type ProductExpectedInbound = {
  readonly __typename?: 'ProductExpectedInbound';
  /** Expected Inbound Date */
  readonly expectedInboundDate?: Maybe<Scalars['Date']['output']>;
  /** Expected Inbound Label */
  readonly expectedInboundLabel?: Maybe<Scalars['String']['output']>;
};

export type ProductFormattedPrice = {
  readonly __typename?: 'ProductFormattedPrice';
  /** Business points assigned to the product */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Timestamp of when price was calculated */
  readonly dateCalculated?: Maybe<Scalars['Date']['output']>;
  /** Object containing concept's prices */
  readonly price?: Maybe<FormattedPrice>;
};

export type ProductLabel = {
  readonly __typename?: 'ProductLabel';
  /** Help text */
  readonly helpText?: Maybe<Scalars['String']['output']>;
  /** Key of the label */
  readonly labelKey?: Maybe<Scalars['ID']['output']>;
  /** Product label domain type */
  readonly productLabelDomain?: Maybe<ProductLabelDomainType>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Product Label Domain Type */
export enum ProductLabelDomainType {
  /** Only Offer, New, SuperDeal */
  Deal = 'Deal',
  /** All of the ProductLabelType except Offer, New, SuperDeal, AddToBasketVisible */
  Ppa = 'PPA',
  /** Domain Type for AddToBasketVisible ProductLabelType */
  Shopping = 'Shopping'
}

/** Product list of specified product type, for example: Personal, Favorite ... */
export type ProductList = {
  readonly __typename?: 'ProductList';
  /** Date of creation */
  readonly created?: Maybe<Scalars['Date']['output']>;
  /** Collection of product items */
  readonly items?: Maybe<ReadonlyArray<Maybe<ProductListItem>>>;
  /** Name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Id */
  readonly productListId: Scalars['ID']['output'];
  /** Type of product list */
  readonly productListType?: Maybe<ProductListType>;
};

/** Product list item */
export type ProductListItem = {
  readonly __typename?: 'ProductListItem';
  /** Product code */
  readonly itemCode?: Maybe<Scalars['String']['output']>;
};

export enum ProductListType {
  Favorite = 'Favorite',
  Personal = 'Personal',
  Private = 'Private',
  Wishlist = 'Wishlist'
}

/** Product listing banner */
export type ProductListingBanner = {
  readonly __typename?: 'ProductListingBanner';
  /** Flag indicating if content should be vertically aligned */
  readonly contentVerticalAlignment?: Maybe<Scalars['String']['output']>;
  /** Banner's headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Url of image on hover */
  readonly imageHoverUrl?: Maybe<Scalars['String']['output']>;
  /** Image's url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Flag indicating if text should be upper case */
  readonly isUpperCase?: Maybe<Scalars['Boolean']['output']>;
  /** Banner's link */
  readonly link?: Maybe<Link>;
  /** How many spots in listing does the banner takes (1 or 2) */
  readonly size?: Maybe<Scalars['Int']['output']>;
  /** Text's color */
  readonly textColour?: Maybe<Scalars['String']['output']>;
};

/** Enum ProductListingBannerVideoType */
export enum ProductListingBannerVideoType {
  Mp4 = 'Mp4',
  None = 'None',
  Youku = 'Youku',
  Youtube = 'Youtube'
}

/** Product Listing Page */
export type ProductListingPage = {
  readonly __typename?: 'ProductListingPage';
  /** Listed items (Products and Banners) */
  readonly contentCollection?: Maybe<ReadonlyArray<Maybe<ProductListingPageContent>>>;
  /** Page's Description */
  readonly desription?: Maybe<Scalars['String']['output']>;
  /** Main image's url */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Page's Name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Page's ID */
  readonly pageId: Scalars['ID']['output'];
  /** Related editorial pages */
  readonly relatedEditorialPages?: Maybe<ReadonlyArray<Maybe<EditorialPage>>>;
  /** Page's Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};


/** Product Listing Page */
export type ProductListingPageRelatedEditorialPagesArgs = {
  channelType: EditorialChannelType;
};

/** Content of the product listing page */
export type ProductListingPageContent = Product | ProductListingPromoBanner;

/** Product attached to banner */
export type ProductListingProduct = {
  readonly __typename?: 'ProductListingProduct';
  /** X coordinate of price's position */
  readonly pricePosX?: Maybe<Scalars['Int']['output']>;
  /** Y coordinate of price's position */
  readonly pricePosY?: Maybe<Scalars['Int']['output']>;
  /** Product */
  readonly product?: Maybe<Product>;
};

/** Promo Banner for Product Listing Page */
export type ProductListingPromoBanner = {
  readonly __typename?: 'ProductListingPromoBanner';
  /** Product Listing Banner */
  readonly productListingBanner?: Maybe<ProductListingBanner>;
  /** Product attached to banner */
  readonly productListingProduct?: Maybe<ProductListingProduct>;
  /** Video attached to the banner */
  readonly productListingVideo?: Maybe<ProductListingVideo>;
  /** Banner's ID */
  readonly promoBannerId: Scalars['ID']['output'];
};

/** Video attached to the banner */
export type ProductListingVideo = {
  readonly __typename?: 'ProductListingVideo';
  /** Flag indicating if autoplay on scroll should be enabled */
  readonly autoplayOnScroll?: Maybe<Scalars['Boolean']['output']>;
  /** Video id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Flag indicating if video should be played in a loop */
  readonly playInLoop?: Maybe<Scalars['Boolean']['output']>;
  /** Video type */
  readonly type?: Maybe<ProductListingBannerVideoType>;
};

export type ProductPermission = {
  readonly __typename?: 'ProductPermission';
  /** Product can be put into the basket owned by specified category of the customer */
  readonly canBuy?: Maybe<Scalars['Boolean']['output']>;
  /** Product can be displayed on web site or any other mobile application. Product display might not be available if the flag is set to false. */
  readonly canDisplay?: Maybe<Scalars['Boolean']['output']>;
  /** Logged-in customer of specified category can put a product to current/active basket */
  readonly canSell?: Maybe<Scalars['Boolean']['output']>;
  /** Code that identifies the resulting product */
  readonly productCode?: Maybe<Scalars['String']['output']>;
};

export type ProductPrice = {
  readonly __typename?: 'ProductPrice';
  /** Business points */
  readonly bp?: Maybe<Scalars['Float']['output']>;
  /** Currency symbol, for example: "€", "$" */
  readonly currency?: Maybe<Scalars['String']['output']>;
  /** Date calculated */
  readonly dateCalculated?: Maybe<Scalars['Date']['output']>;
  /** Prices */
  readonly price?: Maybe<Price>;
};

export type ProductRecommendation = {
  readonly __typename?: 'ProductRecommendation';
  /** Recommended product */
  readonly recommendedProduct?: Maybe<Product>;
  /** Recommended product code */
  readonly recommendedProductCode?: Maybe<Scalars['String']['output']>;
  /** Recommended product similarity to the original product */
  readonly similarity?: Maybe<Scalars['Float']['output']>;
};

/** Collection of related product codes */
export type ProductRelation = {
  readonly __typename?: 'ProductRelation';
  /** Related products */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Relation type */
  readonly relationType?: Maybe<ProductRelationEnum>;
};

/** Relation types */
export enum ProductRelationEnum {
  RecentlyViewed = 'RecentlyViewed',
  Related = 'Related'
}

export type ProductReplacement = {
  readonly __typename?: 'ProductReplacement';
  /** Replacement product */
  readonly product?: Maybe<Product>;
  /** Replacement quantity */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

/** Product search page */
export type ProductSearchPage = {
  readonly __typename?: 'ProductSearchPage';
  /** Query products based on the specified parameters. Returns the products matching the search criteria. */
  readonly products?: Maybe<ProductSearchPageProducts>;
};


/** Product search page */
export type ProductSearchPageProductsArgs = {
  facetFiltering?: InputMaybe<ReadonlyArray<InputMaybe<PlpRowsFacetFilterInput>>>;
  orderBy?: PlpOrderBy;
  query: Scalars['String']['input'];
  skipRows?: InputMaybe<Scalars['Int']['input']>;
  topRows: Scalars['Int']['input'];
};

/** Product search page products */
export type ProductSearchPageProducts = {
  readonly __typename?: 'ProductSearchPageProducts';
  /** Facets */
  readonly facets?: Maybe<ReadonlyArray<Maybe<PlpFacet>>>;
  /** Order by options */
  readonly orderByOptions?: Maybe<ReadonlyArray<Maybe<PlpOrderingDefinition>>>;
  /** Rows */
  readonly rows?: Maybe<ReadonlyArray<Maybe<PlpRow>>>;
  /** Total products count */
  readonly totalProductsCount: Scalars['Int']['output'];
};

export type ProductStandards = {
  readonly __typename?: 'ProductStandards';
  /** Section's headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Product standards images */
  readonly images?: Maybe<ReadonlyArray<Maybe<FrontPageImage>>>;
  /** Section's main text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type ProductToolingBanner = {
  readonly __typename?: 'ProductToolingBanner';
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image URL */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link */
  readonly link?: Maybe<Link>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

export enum ProductType {
  Bundle = 'Bundle',
  Composite = 'Composite',
  Fee = 'Fee',
  Product = 'Product',
  Sample = 'Sample'
}

/** Products promotion banner */
export type ProductsPromotionBanner = {
  readonly __typename?: 'ProductsPromotionBanner';
  /** Font color of the iconic products banner. */
  readonly fontColor?: Maybe<StorybookColor>;
  /** Headline of the iconic products banner. */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** URL of the image for the iconic products banner. */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link associated with the iconic products banner. */
  readonly link?: Maybe<Link>;
};

/** Query root */
export type Query = {
  readonly __typename?: 'Query';
  /** Application */
  readonly application?: Maybe<Application>;
  /** Personal beauty store */
  readonly beautyStore?: Maybe<PersonalBeautyStore>;
  /** Provides categories */
  readonly categories?: Maybe<ReadonlyArray<Maybe<Category>>>;
  /** Provides the category */
  readonly category?: Maybe<Category>;
  /** Chat */
  readonly chat?: Maybe<Chat>;
  /** Provides the concept */
  readonly concept?: Maybe<Concept>;
  /** Provides concepts */
  readonly concepts?: Maybe<ReadonlyArray<Maybe<Concept>>>;
  /** Customer profile */
  readonly customer?: Maybe<Customer>;
  /** Provides ingredients */
  readonly ingredients?: Maybe<ReadonlyArray<Maybe<Ingredient>>>;
  /** Invite friend profile */
  readonly inviteFriendProfile?: Maybe<InviteFriendProfile>;
  /** Provides languages */
  readonly languages?: Maybe<ReadonlyArray<Maybe<Language>>>;
  /** Provides content related to the mobile apps */
  readonly mobileApps?: Maybe<MobileApps>;
  /** Provides Newsletter Subscription Configuration */
  readonly newsletterSubsciptionConfiguration?: Maybe<NewsletterSubsciptionConfiguration>;
  /** Provides the product */
  readonly product?: Maybe<Product>;
  /** Provides products */
  readonly products?: Maybe<ReadonlyArray<Maybe<Product>>>;
  /** Provides collection of Social Media Links */
  readonly socialMediaLinks?: Maybe<ReadonlyArray<Maybe<SocialMediaLinks>>>;
  /** Live video shopping shows */
  readonly videoShows?: Maybe<ReadonlyArray<Maybe<VideoShow>>>;
  /** Vip Customer Banner */
  readonly vipCustomerBanner?: Maybe<VipCustomerBanner>;
};


/** Query root */
export type QueryBeautyStoreArgs = {
  customerId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['ID']['input']>;
};


/** Query root */
export type QueryCategoriesArgs = {
  taxonomyId?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


/** Query root */
export type QueryCategoryArgs = {
  taxonomyId: Scalars['ID']['input'];
};


/** Query root */
export type QueryConceptArgs = {
  conceptCode: Scalars['ID']['input'];
};


/** Query root */
export type QueryConceptsArgs = {
  conceptCodes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  top?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryIngredientsArgs = {
  ingredientNames?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['String']['input']>>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  top?: InputMaybe<Scalars['Int']['input']>;
};


/** Query root */
export type QueryInviteFriendProfileArgs = {
  consultantNumber: Scalars['Int']['input'];
};


/** Query root */
export type QueryProductArgs = {
  productCode: Scalars['ID']['input'];
};


/** Query root */
export type QueryProductsArgs = {
  conceptCodes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
  productCodes?: InputMaybe<ReadonlyArray<InputMaybe<Scalars['ID']['input']>>>;
};


/** Query root */
export type QueryVideoShowsArgs = {
  customerId: Scalars['ID']['input'];
};

export type ReferralProgram = {
  readonly __typename?: 'ReferralProgram';
  /** Allowed contacts */
  readonly allowedContacts?: Maybe<ReadonlyArray<Maybe<SocialContactType>>>;
  /** Referral link */
  readonly benefitsLink?: Maybe<Link>;
  /** Greetings message */
  readonly greetingMessage?: Maybe<Scalars['String']['output']>;
  /** Message */
  readonly message?: Maybe<Scalars['String']['output']>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type Replacement = {
  readonly __typename?: 'Replacement';
  /** Unique replacement code */
  readonly code?: Maybe<Scalars['String']['output']>;
  /** Replacement quantity */
  readonly quantity?: Maybe<Scalars['Int']['output']>;
};

/** ResponseMessageNewsletterSubsciptionConfiguration */
export type ResponseMessageNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'ResponseMessageNewsletterSubsciptionConfiguration';
  /** text */
  readonly text?: Maybe<Scalars['String']['output']>;
  /** Type */
  readonly type?: Maybe<ResponseMessageNewsletterSubsciptionsType>;
};

/** ResponseMessageNewsletterSubsciptionsType */
export enum ResponseMessageNewsletterSubsciptionsType {
  /** Cleaned */
  Cleaned = 'Cleaned',
  /** New */
  New = 'New',
  /** Pending */
  Pending = 'Pending',
  /** Subscribed */
  Subscribed = 'Subscribed',
  /** Unsubscribed */
  Unsubscribed = 'Unsubscribed'
}

export enum SettingsClientType {
  OnlineReportingGrid = 'OnlineReportingGrid',
  WellnessClub = 'WellnessClub'
}

export enum SettingsFormatType {
  Json = 'Json',
  String = 'String',
  Xml = 'Xml'
}

/** Share and earn page template */
export type ShareAndEarn = {
  readonly __typename?: 'ShareAndEarn';
  /** Frequently Asked Questions section */
  readonly faqSection?: Maybe<ShareAndEarnFaqSection>;
  /** How it works section */
  readonly howItWorksSection?: Maybe<ShareAndEarnHowItWorksSection>;
  /** Personal code edit page */
  readonly personalCodeEditPage?: Maybe<ShareAndEarnPersonalCodeEditPage>;
  /** Personal links content */
  readonly personalLinksContent?: Maybe<ShareAndEarnPersonalLinks>;
  /** Share and earn section */
  readonly shareAndEarnSection?: Maybe<ShareAndEarnShareSection>;
  /** Sharing conditions */
  readonly sharingConditions?: Maybe<ShareAndEarnConditions>;
  /** Top section */
  readonly topSection?: Maybe<ShareAndEarnTopSection>;
};

/** Share and earn condition item */
export type ShareAndEarnConditionItem = {
  readonly __typename?: 'ShareAndEarnConditionItem';
  /** Condition text */
  readonly lines?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Condition title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Share and earn conditions */
export type ShareAndEarnConditions = {
  readonly __typename?: 'ShareAndEarnConditions';
  /** Condition collection */
  readonly collection?: Maybe<ReadonlyArray<Maybe<ShareAndEarnConditionItem>>>;
  /** Conditions headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
};

/** Share and earn FAQ item */
export type ShareAndEarnFaqItem = {
  readonly __typename?: 'ShareAndEarnFaqItem';
  /** Content */
  readonly content?: Maybe<ReadonlyArray<Maybe<Scalars['String']['output']>>>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type ShareAndEarnFaqSection = {
  readonly __typename?: 'ShareAndEarnFaqSection';
  /** Collection of FAQ items */
  readonly collection?: Maybe<ReadonlyArray<Maybe<ShareAndEarnFaqItem>>>;
  /** Headline of the section */
  readonly headline?: Maybe<Scalars['String']['output']>;
};

/** Share and earn page How it works section */
export type ShareAndEarnHowItWorksSection = {
  readonly __typename?: 'ShareAndEarnHowItWorksSection';
  /** Conditions link title */
  readonly conditionsLinkTitle?: Maybe<Scalars['String']['output']>;
  /** Earn description */
  readonly earnDescription?: Maybe<Scalars['String']['output']>;
  /** Earn title */
  readonly earnTitle?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Invite description */
  readonly inviteDescription?: Maybe<Scalars['String']['output']>;
  /** Invite title */
  readonly inviteTitle?: Maybe<Scalars['String']['output']>;
  /** Share description */
  readonly shareDescription?: Maybe<Scalars['String']['output']>;
  /** Share title */
  readonly shareTitle?: Maybe<Scalars['String']['output']>;
};

/** Share and earn personal code edit page */
export type ShareAndEarnPersonalCodeEditPage = {
  readonly __typename?: 'ShareAndEarnPersonalCodeEditPage';
  /** Button applied label */
  readonly buttonAppliedLabel?: Maybe<Scalars['String']['output']>;
  /** Button cancel label */
  readonly buttonCancelLabel?: Maybe<Scalars['String']['output']>;
  /** Button change label */
  readonly buttonChangeLabel?: Maybe<Scalars['String']['output']>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Invalid code message */
  readonly invalidCodeMessage?: Maybe<Scalars['String']['output']>;
  /** Your code label */
  readonly yourCodeLabel?: Maybe<Scalars['String']['output']>;
};

/** Share and earn page personal links */
export type ShareAndEarnPersonalLinks = {
  readonly __typename?: 'ShareAndEarnPersonalLinks';
  /** Copy label */
  readonly copyLabel?: Maybe<Scalars['String']['output']>;
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Instructions */
  readonly instructions?: Maybe<Scalars['String']['output']>;
  /** Links */
  readonly links?: Maybe<ReadonlyArray<Maybe<SharingLink>>>;
  /** Title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Share and earn page share section */
export type ShareAndEarnShareSection = {
  readonly __typename?: 'ShareAndEarnShareSection';
  /** Description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Sharing card */
  readonly sharingCard?: Maybe<SharingCard>;
};

/** Share and earn page top section */
export type ShareAndEarnTopSection = {
  readonly __typename?: 'ShareAndEarnTopSection';
  /** Top section description */
  readonly description?: Maybe<Scalars['String']['output']>;
  /** Top section headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
};

/** Input data for updating personal code in share and earn. */
export type ShareAndEarnUpdatePersonalCodeInput = {
  /** The customer ID of the customer who wants to update the personal code. */
  readonly customerId: Scalars['String']['input'];
  /** The new personal code */
  readonly newPersonalCode: Scalars['String']['input'];
};

/** Share and earn sharing card */
export type SharingCard = {
  readonly __typename?: 'SharingCard';
  /** From label */
  readonly fromLabel?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
  /** Use code label */
  readonly useCodeLabel?: Maybe<Scalars['String']['output']>;
};

/** Sharing link */
export type SharingLink = {
  readonly __typename?: 'SharingLink';
  /** Link definition */
  readonly link?: Maybe<Link>;
  /** Link tooltip */
  readonly tooltip?: Maybe<Scalars['String']['output']>;
};

/** Links meant for sharing by the user. */
export type SharingLinks = {
  readonly __typename?: 'SharingLinks';
  /** Link to the current digital catalogue */
  readonly currentCatalogue?: Maybe<Link>;
};


/** Links meant for sharing by the user. */
export type SharingLinksCurrentCatalogueArgs = {
  pageNumber?: InputMaybe<Scalars['Int']['input']>;
};

/** Live video show type */
export enum ShowType {
  /** Show is recorded */
  Ended = 'Ended',
  /** Show is running now */
  Live = 'Live',
  /** Show not started yet */
  Scheduled = 'Scheduled'
}

/** Sized image wariant */
export type SizedImage = {
  readonly __typename?: 'SizedImage';
  /** Url of the image */
  readonly url?: Maybe<Scalars['String']['output']>;
  /** Width of the image */
  readonly width?: Maybe<Scalars['Int']['output']>;
};

/** Social contact */
export type SocialContact = {
  readonly __typename?: 'SocialContact';
  /** Type of contact, eg. phone, WhatsUp, Facebook ... */
  readonly type?: Maybe<SocialContactType>;
  /** Social contact value */
  readonly value?: Maybe<Scalars['String']['output']>;
};

/** Types of social contact */
export enum SocialContactType {
  /** Email */
  Email = 'Email',
  /** Facebook */
  Facebook = 'Facebook',
  /** Phone */
  PhoneNumber = 'PhoneNumber',
  /** VKontakte */
  VKontakte = 'VKontakte',
  /** WeChat */
  WeChat = 'WeChat',
  /** WhatsUp */
  WhatsUpNumber = 'WhatsUpNumber'
}

/** SocialMediaLinks type */
export type SocialMediaLinks = {
  readonly __typename?: 'SocialMediaLinks';
  /** Social Media Link Title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Social Media type */
  readonly type?: Maybe<SocialMediaType>;
  /** Social Media Link url */
  readonly url?: Maybe<Scalars['String']['output']>;
};

/** Types of social media */
export enum SocialMediaType {
  /** Facebook */
  Facebook = 'Facebook',
  /** Instagram */
  Instagram = 'Instagram',
  /** Line */
  Line = 'Line',
  /** Odnoklassniki */
  Odnoklassniki = 'Odnoklassniki',
  /** Pinterest */
  Pinterest = 'Pinterest',
  /** QQ */
  Qq = 'QQ',
  /** Telegram */
  Telegram = 'Telegram',
  /** Twitter */
  Twitter = 'Twitter',
  /** VKontakte */
  VKontakte = 'VKontakte',
  /** WeChat */
  WeChat = 'WeChat',
  /** Weibo */
  Weibo = 'Weibo',
  /** YouTube */
  YouTube = 'YouTube',
  /** Youku */
  Youku = 'Youku'
}

/** Sponsor */
export type Sponsor = {
  readonly __typename?: 'Sponsor';
  /** Consultant number of sponsor */
  readonly consultantNumber?: Maybe<Scalars['Int']['output']>;
  /** Customer ID of sponsor */
  readonly customerId?: Maybe<Scalars['Int']['output']>;
  /** Sponsor emails */
  readonly emails?: Maybe<ReadonlyArray<Maybe<Email>>>;
  /** Full name (formatted) of sponsor */
  readonly fullName?: Maybe<Scalars['String']['output']>;
  /** Sponsor telephone numbers */
  readonly phones?: Maybe<ReadonlyArray<Maybe<Phone>>>;
  /** Sponsor profile image */
  readonly profileImage?: Maybe<DocumentInfo>;
  /** Type of sponsor (relation to customer). */
  readonly sponsorType?: Maybe<SponsorType>;
};

/** Sponsor type */
export enum SponsorType {
  DirectSponsor = 'DirectSponsor'
}

/** Colors enum referring to FE storybook */
export enum StorybookColor {
  /** Shades/palette.blue[100] */
  Blue100 = 'Blue100',
  /** Shades/palette.brown[50] */
  Brown50 = 'Brown50',
  /** Shades/palette.brown[300] */
  Brown300 = 'Brown300',
  /** Shades/palette.brown[400] */
  Brown400 = 'Brown400',
  /** Extra colors/palette.common.white */
  CommonWhite = 'CommonWhite',
  /** Palette - Main colors/palette.default.contrastText */
  DefaultContrastText = 'DefaultContrastText',
  /** Palette - Main colors/palette.default.main */
  DefaultMain = 'DefaultMain',
  /** Shades/palette.green[50] */
  Green50 = 'Green50',
  /** Shades/palette.green[400] */
  Green400 = 'Green400',
  /** Shades/palette.grey[50] */
  Grey50 = 'Grey50',
  /** InfoDark */
  InfoDark = 'InfoDark',
  /** Shades/palette.pink[50] */
  Pink50 = 'Pink50',
  /** Shades/palette.pink[300] */
  Pink300 = 'Pink300',
  /** PrimaryLight */
  PrimaryLight = 'PrimaryLight',
  /** Shades/palette.purple[50] */
  Purple50 = 'Purple50',
  /** Shades/palette.purple[200] */
  Purple200 = 'Purple200',
  /** Shades/palette.purple[400] */
  Purple400 = 'Purple400',
  /** Shades/palette.red[50] */
  Red50 = 'Red50',
  /** Palette - Main colors/palette.secondary.main */
  SecondaryMain = 'SecondaryMain',
  /** Typography/palette.text.primary */
  TextPrimary = 'TextPrimary',
  /** Typography/palette.text.secondary */
  TextSecondary = 'TextSecondary',
  /** Undefined value, its an error when this occurs */
  Undefined = 'Undefined',
  /** Shades/palette.yellow[50] */
  Yellow50 = 'Yellow50',
  /** Shades/palette.yellow[400] */
  Yellow400 = 'Yellow400'
}

/** SubmitDialogNewsletterSubsciptionConfiguration */
export type SubmitDialogNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'SubmitDialogNewsletterSubsciptionConfiguration';
  /** EmailLabel */
  readonly emailLabel?: Maybe<Scalars['String']['output']>;
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** InvalidEmailLabel */
  readonly invalidEmailLabel?: Maybe<Scalars['String']['output']>;
  /** PrivacyPolicyText */
  readonly privacyPolicyText?: Maybe<Scalars['String']['output']>;
  /** PrivacyPolicyUrl */
  readonly privacyPolicyUrl?: Maybe<Scalars['String']['output']>;
  /** PrivacyPolicyUrlText */
  readonly privacyPolicyUrlText?: Maybe<Scalars['String']['output']>;
  /** SubscribeButtonText */
  readonly subscribeButtonText?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

/** SubscribedDialogNewsletterSubsciptionConfiguration */
export type SubscribedDialogNewsletterSubsciptionConfiguration = {
  readonly __typename?: 'SubscribedDialogNewsletterSubsciptionConfiguration';
  /** Headline */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Text */
  readonly text?: Maybe<Scalars['String']['output']>;
};

export type SubscriptionBenefitBanner = {
  readonly __typename?: 'SubscriptionBenefitBanner';
  /** Banner message */
  readonly message?: Maybe<Scalars['String']['output']>;
  /** Link to read more information on subscriptions */
  readonly readMoreLink?: Maybe<Link>;
  /** Link to register new customer */
  readonly registerLink?: Maybe<Link>;
  /** Link to sign in existing customer */
  readonly signInLink?: Maybe<Link>;
  /** Banner title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

export type Tag = {
  readonly __typename?: 'Tag';
  /** Tag group */
  readonly groupName?: Maybe<Scalars['String']['output']>;
  /** Taxonomy tag group name */
  readonly name?: Maybe<Scalars['String']['output']>;
  /** Collection of tags */
  readonly tags?: Maybe<ReadonlyArray<Maybe<TagValue>>>;
  /** Tag group translation */
  readonly translation?: Maybe<Scalars['String']['output']>;
};

export type TagValue = {
  readonly __typename?: 'TagValue';
  /** Value of a tag */
  readonly value?: Maybe<Scalars['String']['output']>;
};

export type TaxClassification = {
  readonly __typename?: 'TaxClassification';
  /** Entrepreneur tax related documents including historical */
  readonly entrepreneurDocuments?: Maybe<ReadonlyArray<Maybe<TaxClassificationDocument>>>;
  /** Indicates whether customer is entrepreneur */
  readonly isEntrepreneur?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether customer is physical person */
  readonly isPhysicalPerson?: Maybe<Scalars['Boolean']['output']>;
  /** Indicates whether customer is vat payer */
  readonly isVatPayer?: Maybe<Scalars['Boolean']['output']>;
  /** Physical person tax related documents including historical */
  readonly physicalPersonDocuments?: Maybe<ReadonlyArray<Maybe<TaxClassificationDocument>>>;
  /** Vat payer related documents including historical */
  readonly vatPayerDocuments?: Maybe<ReadonlyArray<Maybe<TaxClassificationDocument>>>;
};

export type TaxClassificationDocument = {
  readonly __typename?: 'TaxClassificationDocument';
  /** Company name */
  readonly companyName?: Maybe<Scalars['String']['output']>;
  /** Document number */
  readonly documentNumber?: Maybe<Scalars['String']['output']>;
  /** Whether document is valid and used (e.g. not deleted by operator) */
  readonly isActive?: Maybe<Scalars['Boolean']['output']>;
  /** Issue Date */
  readonly issueDate?: Maybe<Scalars['Date']['output']>;
  /** ID of tax classification */
  readonly taxClassificationId: Scalars['ID']['output'];
  /** Validity of document - start */
  readonly validFrom?: Maybe<Scalars['Date']['output']>;
  /** Validity of document - end */
  readonly validTo?: Maybe<Scalars['Date']['output']>;
};

/** Enum telephone type */
export enum TelephoneType {
  Business = 'Business',
  Fax = 'Fax',
  Home = 'Home',
  Mobile = 'Mobile'
}

export type TopBrands = {
  readonly __typename?: 'TopBrands';
  /** Brands displayed in the section */
  readonly brands?: Maybe<ReadonlyArray<Maybe<Brand>>>;
  /** Section's title */
  readonly title?: Maybe<Scalars['String']['output']>;
};

/** Content vertical alignment */
export enum VerticalContentAlignment {
  Bottom = 'Bottom',
  Middle = 'Middle',
  Top = 'Top',
  Undefined = 'Undefined'
}

export type Video = {
  readonly __typename?: 'Video';
  /** Flag indicating if video should be automatcially played on scroll */
  readonly autoplayOnScroll?: Maybe<Scalars['Boolean']['output']>;
  /** Video's id */
  readonly id?: Maybe<Scalars['String']['output']>;
  /** Flag indicating if video should be played in a loop */
  readonly playInLoop?: Maybe<Scalars['Boolean']['output']>;
  /** Type of the video */
  readonly type?: Maybe<VideoType>;
};

/** Group of VideoShopping resources */
export type VideoShopping = {
  readonly __typename?: 'VideoShopping';
  /** Live Video Shopping configuration */
  readonly configuration?: Maybe<VideoShoppingConfiguration>;
  /** Live Video Shopping localization */
  readonly localization?: Maybe<VideoShoppingLocalization>;
};

/** Video shopping configuration */
export type VideoShoppingConfiguration = {
  readonly __typename?: 'VideoShoppingConfiguration';
  /** Add This social network sharer */
  readonly addThis?: Maybe<Scalars['JSON']['output']>;
  /** IS Spa app. landing page enabled */
  readonly isSpaLandingPageEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is video shopping enabled */
  readonly isVideoShoppingEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Use preview image for show in landing page */
  readonly usePreviewImage?: Maybe<Scalars['Boolean']['output']>;
};

/** Video Shopping Localization */
export type VideoShoppingLocalization = {
  readonly __typename?: 'VideoShoppingLocalization';
  /** Cultures */
  readonly cultures?: Maybe<Scalars['JSON']['output']>;
  /** Default language */
  readonly defaultLanguage?: Maybe<Scalars['String']['output']>;
  /** Translations */
  readonly translations?: Maybe<Scalars['JSON']['output']>;
};

/** live video show */
export type VideoShow = {
  readonly __typename?: 'VideoShow';
  /** Duration of show in sec. */
  readonly durationSeconds?: Maybe<Scalars['Int']['output']>;
  /** Time when show was ended */
  readonly endedAtUtc?: Maybe<Scalars['Date']['output']>;
  /** Identifying show which should be displayed in banner */
  readonly isCurrent?: Maybe<Scalars['Boolean']['output']>;
  /** Preview image of show */
  readonly previewImageUrl?: Maybe<Scalars['String']['output']>;
  /** Scheduled start of show */
  readonly scheduledStartAtUtc?: Maybe<Scalars['Date']['output']>;
  /** Id of show */
  readonly showId: Scalars['ID']['output'];
  /** Real time of show start */
  readonly startedAtUtc?: Maybe<Scalars['Date']['output']>;
  /** Show title */
  readonly title?: Maybe<Scalars['String']['output']>;
  /** Type of show */
  readonly typeOfShow?: Maybe<ShowType>;
};

export enum VideoType {
  /** Video in MP4 file format */
  Mp4 = 'Mp4',
  /** Video hosted on Youku */
  Youku = 'Youku',
  /** Video hosted on YouTube */
  Youtube = 'Youtube'
}

export type VipBenefits = {
  readonly __typename?: 'VipBenefits';
  /** Collection of images displayed in VIP Benefits section on the Frontpage */
  readonly images?: Maybe<ReadonlyArray<Maybe<FrontPageImage>>>;
  /** Link of VIP Benefits section */
  readonly link?: Maybe<Link>;
};

export type VipCustomerBanner = {
  readonly __typename?: 'VipCustomerBanner';
  /** Vip Customer Banner link title */
  readonly linkTitle?: Maybe<Scalars['String']['output']>;
  /** Vip Customer Banner link url */
  readonly linkUrl?: Maybe<Scalars['String']['output']>;
};

/** Possible types of visitor segment */
export enum VisitorSegment {
  /** Anonymous */
  Anonymous = 'Anonymous',
  /** Consultant */
  Consultant = 'Consultant',
  /** Director and above */
  DirectorAndAbove = 'DirectorAndAbove',
  /** End customer */
  EndCustomer = 'EndCustomer',
  /** Manager */
  Manager = 'Manager',
  /** Member */
  Member = 'Member',
  /** New consultant */
  NewConsultant = 'NewConsultant',
  /** Not defined */
  NotDefined = 'NotDefined',
  /** Spo */
  Spo = 'Spo'
}

/** Group of Wellness Club resources */
export type WellnessClub = {
  readonly __typename?: 'WellnessClub';
  /** Wellness club banners */
  readonly banners?: Maybe<WellnessClubBanners>;
  /** Wellness Club configuration */
  readonly configuration?: Maybe<WellnessClubConfiguration>;
  /** Wellness Club localization */
  readonly localization?: Maybe<WellnessClubLocalization>;
};

/** Wellness Club banners */
export type WellnessClubBanners = {
  readonly __typename?: 'WellnessClubBanners';
  /** Daily wellnessbeing banner */
  readonly dailyWellbeingBannerUrl?: Maybe<Scalars['String']['output']>;
  /** Wellness club main banner */
  readonly mainBannerUrl?: Maybe<Scalars['String']['output']>;
  /** Sport track banner */
  readonly sportBannerUrl?: Maybe<Scalars['String']['output']>;
  /** Weight management banner */
  readonly weightManagementBannerUrl?: Maybe<Scalars['String']['output']>;
};

/** Wellness Club configuration */
export type WellnessClubConfiguration = {
  readonly __typename?: 'WellnessClubConfiguration';
  /** Get certification page url */
  readonly certifiedUrl?: Maybe<Scalars['String']['output']>;
  /** Is club members management enabled */
  readonly isClubMembersManagementEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is daily wellnessbeing track enabled */
  readonly isDailyWellbeingTrackEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is wellness club enabled */
  readonly isEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is sports track enabled */
  readonly isSportsTrackEnabled?: Maybe<Scalars['Boolean']['output']>;
  /** Is weight management track enabled */
  readonly isWeightManagementTrackEnabled?: Maybe<Scalars['Boolean']['output']>;
};

/** Wellness Club localization */
export type WellnessClubLocalization = {
  readonly __typename?: 'WellnessClubLocalization';
  /** Cultures */
  readonly cultures?: Maybe<Scalars['JSON']['output']>;
  /** Default language */
  readonly defaultLanguage?: Maybe<Scalars['String']['output']>;
  /** Translations */
  readonly translations?: Maybe<Scalars['JSON']['output']>;
};

export type WhatsNewBanner = {
  readonly __typename?: 'WhatsNewBanner';
  /** Font color -eg. red, grey */
  readonly fontColor?: Maybe<Scalars['String']['output']>;
  /** Headline of the banner */
  readonly headline?: Maybe<Scalars['String']['output']>;
  /** Image url of the banner */
  readonly imageUrl?: Maybe<Scalars['String']['output']>;
  /** Link type, attributes and Id */
  readonly link?: Maybe<Link>;
  /** Mobile font color -eg. red, grey */
  readonly mobileFontColor?: Maybe<Scalars['String']['output']>;
  /** Banner Validity date from */
  readonly validFrom?: Maybe<Scalars['Date']['output']>;
  /** Banner validity date to */
  readonly validTo?: Maybe<Scalars['Date']['output']>;
};
