import { Typography, styled } from '@ori-ui/mui';

export const Root = styled(Typography)(({ theme }) => ({
  display: '-webkit-box',
  maxWidth: 600,
  margin: 'auto',
  overflow: 'hidden',
  WebkitLineClamp: 6,
  WebkitBoxOrient: 'vertical',
  [theme.breakpoints.up('sm')]: {
    WebkitLineClamp: 4,
  },
}));
