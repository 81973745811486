import { gql } from '@ori/fetching';

import { editorialArticlePageFragment } from './editorialArticlePageFragment';
import { editorialCategoryPageFragment } from './editorialCategoryPageFragment';

export const editorialCategoryOrPageQuery = gql`
  query EditorialCategoryOrPage($pageId: ID!) {
    application {
      ...EditorialArticlePage
      ...EditorialCategoryPage
    }
  }
  ${editorialCategoryPageFragment}
  ${editorialArticlePageFragment}
`;
