import { styled } from '@ori-ui/mui';
import {
  ARTICLE_BOX_DESKTOP_WIDTH,
  EDITORIAL_CAROUSEL_SLIDE_MOBILE_MIN_WIDTH,
  PaginationSkeleton as PaginationSkeletonComponent,
} from '@ori/presentation-components';

export const PaginationSkeleton = styled(PaginationSkeletonComponent)(({ theme }) => ({
  margin: 'auto',
  maxWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 360,
  },
}));

export const CarouselSkeleton = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: `repeat(6, ${EDITORIAL_CAROUSEL_SLIDE_MOBILE_MIN_WIDTH}px)`,
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(10),
    gridTemplateColumns: `repeat(6, ${ARTICLE_BOX_DESKTOP_WIDTH}px)`,
  },
}));
