import { useCallback, useState } from 'react';

import { DEFAULT_PAGE_SIZE } from './constants';
import { getCountOfPages } from './getCountOfPages';
import { parseArticles } from './parseArticles';
import type { UseGetArticlesParams } from './types';

/**
 * Hook accepts articles loaded from GraphQL and parses them through `parseArticles`. Decides page count based on `getCountOfPages`.
 * Returns parsed articles pages (`articles`), `countOfPages`, info about possible articles fetching (`loadingArticles`)
 * and callback to load another page of articles (`loadMore`).
 */
export const useGetArticles = ({
  articles: initialArticles,
  countOfArticles = 0,
  pageSize = DEFAULT_PAGE_SIZE,
}: UseGetArticlesParams) => {
  const articles = parseArticles({ articles: initialArticles });
  const countOfPages = getCountOfPages({ countOfArticles, pageSize });
  const [page, setPage] = useState(1);
  const loadMore = useCallback(
    () => setPage((prevPage) => (prevPage < countOfPages ? prevPage + 1 : prevPage)),
    [countOfPages],
  );

  return {
    articles: articles.slice(0, page * pageSize),
    countOfPages,
    loadingArticles: false,
    loadMore,
  };
};
