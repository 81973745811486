import {
  CarouselSlide,
  CATEGORY_BOX_DESKTOP_WIDTH,
  CATEGORY_BOX_MOBILE_WIDTH,
  CategoryBox,
} from '@ori/presentation-components';
import { useIsMobile } from '@ori/presentation-hooks';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import React from 'react';

import { Title } from '../Title';
import { CATEGORIES_SECTION } from './constants';
import { parseCategories } from './parseCategories';
import { Carousel, Root } from './styles';
import type { CategoriesSectionProps } from './types';
import { useCarouselOptions } from './useCarouselOptions';

/**
 * Returns section with `CategoryBox` `Carousel` and `Title`.
 * Renders if there is at least 1 category with thruthy data.
 */
export const CategoriesSection: FC<CategoriesSectionProps> = ({ categories, className, title, viewButtonText }) => {
  const { getTestId } = useTestId();
  const isMobile = useIsMobile();
  const options = useCarouselOptions();
  const parsedCategories = parseCategories({ categories });
  if (parsedCategories.length < 1) {
    return null;
  }

  return (
    <Root
      className={className}
      data-testid={getTestId(CATEGORIES_SECTION)}
    >
      <Title title={title} />
      <Carousel
        {...options}
        id="editorials-categories-section-carousel"
      >
        {parsedCategories.map(({ imageUrl, relativeUrl, title: categoryTitle }) => (
          <CarouselSlide
            key={relativeUrl}
            minWidth={isMobile ? CATEGORY_BOX_MOBILE_WIDTH : CATEGORY_BOX_DESKTOP_WIDTH}
          >
            <CategoryBox
              buttonText={viewButtonText}
              imageUrl={imageUrl}
              relativeUrl={relativeUrl}
              title={categoryTitle}
            />
          </CarouselSlide>
        ))}
      </Carousel>
    </Root>
  );
};
