import { gql } from '@ori/fetching';

export const editorialPageFragment = gql`
  fragment EditorialPage on EditorialPage {
    __typename
    description
    pageId
    title
    thumbnailUrl
    themeTag
  }
`;
