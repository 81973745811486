/* istanbul ignore file */
import { categories } from '@ori/editorial-fetching';

import type { CategoriesSectionProps } from '..';

export const categoriesSectionProps: CategoriesSectionProps = {
  categories,
  title: 'Categories',
  viewButtonText: 'View',
};
