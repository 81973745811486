import { styled } from '@ori-ui/mui';

import { UnifiedSkeleton } from '../styles';

export const Root = styled('div')(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 600,
  margin: 'auto',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(10),
  },
}));

export const Title = styled(UnifiedSkeleton)(({ theme }) => ({
  width: 155,
  height: 47,
  [theme.breakpoints.up('sm')]: {
    width: 205,
    height: 62,
  },
}));
