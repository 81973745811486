import { gql } from '@ori/fetching';

import { editorialPageFragment } from './editorialPageFragment';

const editorialCategoryFragment = gql`
  fragment EditorialCategory on EditorialLandingPage {
    landingPageId
    imageUrl
    thumbnailUrl
    description
    themeTag
  }
`;

export const editorialCategoryPageFragment = gql`
  ${editorialCategoryFragment}
  ${editorialPageFragment}
  fragment EditorialCategoryPage on Application {
    editorialLandingPage(channelType: Web, landingPageId: $pageId) {
      landingPageId
      imageUrl
      thumbnailUrl
      description
      themeTag
      editorialItems {
        ...EditorialPage
      }
    }
    editorialLandingPages(channelType: Web) {
      ...EditorialCategory
    }
  }
`;
