import type { DefaultLayoutPageProps } from '@ori-appshell/layouts';
import { DefaultLayoutPage } from '@ori-appshell/layouts';
import type { TranslationsWithNamespaces } from '@ori/i18n';
import { LoggerProvider } from '@ori/logger';
import type { PropsWithChildren } from 'react';

import { LayoutContentLoader } from '../../components';
import { mainLogger } from '../../logger';

export type LayoutProps<T extends TranslationsWithNamespaces = TranslationsWithNamespaces> = DefaultLayoutPageProps<T>;
interface IndexProps<T extends TranslationsWithNamespaces = TranslationsWithNamespaces> {
  layoutPage: DefaultLayoutPageProps<T>;
  useOpenedMinibagOnDesktop?: boolean;
}

export const Layout = ({ layoutPage, useOpenedMinibagOnDesktop = false, children }: PropsWithChildren<IndexProps>) => (
  <DefaultLayoutPage {...layoutPage}>
    <LoggerProvider logger={mainLogger}>
      <LayoutContentLoader useOpenedMinibagOnDesktop={useOpenedMinibagOnDesktop}>{children}</LayoutContentLoader>
    </LoggerProvider>
  </DefaultLayoutPage>
);
