import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import type { SkeletonProps } from '../types';
import { TITLE_SKELETON } from './constants';
import { Root, Title } from './styles';

/**
 * Renders Skeleton for `Title`.
 */
export const TitleSkeleton: FC<SkeletonProps> = ({ animation = 'pulse', className }) => {
  const { getTestId } = useTestId();

  return (
    <Root
      className={className}
      data-testid={getTestId(TITLE_SKELETON)}
    >
      <Title
        variant="rectangular"
        animation={animation}
      />
    </Root>
  );
};
