import { styled } from '@ori-ui/mui';
import { Carousel as CarouselComponent } from '@ori/presentation-components';

export const Root = styled('div')(({ theme }) => ({
  margin: 'auto',
  maxWidth: 970,
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(20),
  },
}));

export const Carousel = styled(CarouselComponent)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    margin: theme.spacing(0, -12),
  },
}));
