import { ARTICLE_BOX_SKELETON, ArticleBoxSkeleton } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import times from 'lodash.times';
import { useMemo, type FC } from 'react';

import { Articles, Root } from '../ArticlesSection/styles';
import { TitleSkeleton } from '../TitleSkeleton';
import { ARTICLES_SECTION_SKELETON } from './constants';
import { CarouselSkeleton, PaginationSkeleton } from './styles';
import type { ArticlesSectionSkeletonProps } from './types';

/**
 * Renders Skeleton for `ArticlesSection`.
 *
 * @deprecated This component is deprecated as we are using SSG. Will be removed in next update. DO NOT USE.
 */
export const ArticlesSectionSkeleton: FC<ArticlesSectionSkeletonProps> = ({
  animation = 'pulse',
  className,
  mode,
  showTitle,
}) => {
  const { getTestId } = useTestId();
  const countOfArticles = useMemo(() => (mode === 'listing' ? 12 : 6), [mode]);
  const articles = useMemo(
    () =>
      times(countOfArticles).map((_, index) => (
        <ArticleBoxSkeleton
          // eslint-disable-next-line react/no-array-index-key -- No other option for passing data to key
          key={`${ARTICLE_BOX_SKELETON}-${index}`}
          animation={animation}
        />
      )),
    [animation, countOfArticles],
  );

  return (
    <Root
      className={className}
      data-testid={getTestId(ARTICLES_SECTION_SKELETON)}
    >
      {showTitle ? <TitleSkeleton animation={animation} /> : null}
      {mode === 'listing' ? <Articles>{articles}</Articles> : <CarouselSkeleton>{articles}</CarouselSkeleton>}
      {mode === 'listing' ? <PaginationSkeleton animation={animation} /> : null}
    </Root>
  );
};
