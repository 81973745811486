import { styled } from '@ori-ui/mui';
import { ARTICLE_BOX_DESKTOP_WIDTH, EDITORIAL_CAROUSEL_SLIDE_MOBILE_MIN_WIDTH } from '@ori/presentation-components';

import { TitleSkeleton } from '../TitleSkeleton';

export const CarouselSkeleton = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(2),
  gridTemplateColumns: `repeat(6, ${EDITORIAL_CAROUSEL_SLIDE_MOBILE_MIN_WIDTH}px)`,
  overflow: 'hidden',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(10),
    gridTemplateColumns: `repeat(6, ${ARTICLE_BOX_DESKTOP_WIDTH}px)`,
  },
}));

export const Title = styled(TitleSkeleton)(({ theme }) => ({
  marginBottom: 0,
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(0),
  },
}));
