import type { ArticleBoxProps } from '@ori/presentation-components';

import type { ParseArticlesParams } from './types';

/**
 * Utility to filter articles with thruthy data and map them to `ArticleBoxProps` type.
 * @returns Articles with thruthy data
 */
export const parseArticles = ({
  articles,
}: ParseArticlesParams): Array<Omit<ArticleBoxProps, 'labelRead' | 'labelWatch'>> =>
  (articles ?? [])
    .filter((article) => !!article)
    .filter(({ pageId, description, thumbnailUrl, title }) => !!pageId && !!description && !!thumbnailUrl && !!title)
    .map(({ pageId, description, themeTag, thumbnailUrl, title }) => ({
      categoryTitle: themeTag,
      image: { alt: title, url: thumbnailUrl },
      introText: description,
      isVideoArticle: false,
      link: { relativeUrl: pageId },
      title,
    }));
