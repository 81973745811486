import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import React from 'react';

import { DESCRIPTION } from './constants';
import { Root } from './styles';
import type { DescriptionProps } from './types';

/**
 * Returns styled description of a category. On desktop is centered.
 * Renders if `description` has thruthy value.
 */
export const Description: FC<DescriptionProps> = ({ description, className }) => {
  const { getTestId } = useTestId();
  if (!description) {
    return null;
  }

  return (
    <Root
      className={className}
      color="textPrimary"
      data-testid={getTestId(DESCRIPTION)}
    >
      {description}
    </Root>
  );
};
