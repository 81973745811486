import { styled } from '@ori-ui/mui';
import { EditorialCarousel, Pagination as PaginationComponent } from '@ori/presentation-components';

export const Root = styled('div')(({ theme }) => ({
  maxWidth: 970,
  margin: 'auto',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(20),
  },
}));

export const Articles = styled('div')(({ theme }) => ({
  display: 'grid',
  gridGap: theme.spacing(6),
  gridTemplateColumns: '1fr',
  marginBottom: theme.spacing(6),
  [theme.breakpoints.up('sm')]: {
    gridGap: theme.spacing(10),
    gridTemplateColumns: '1fr 1fr 1fr',
    marginBottom: theme.spacing(10),
  },
}));

export const Pagination = styled(PaginationComponent)(({ theme }) => ({
  margin: 'auto',
  maxWidth: '100%',
  [theme.breakpoints.up('sm')]: {
    maxWidth: 360,
  },
}));

export const Carousel = styled(EditorialCarousel)(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    margin: theme.spacing(0, -12),
  },
}));
