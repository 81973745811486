import { DefaultLayoutFeature } from '@ori-appshell/layouts';

export const PageFeatures = {
  ...DefaultLayoutFeature,
  /** It enable/disable sending search events to analytics. */
  SendSearchEvents: '3767D0FE-0404-4FC8-A552-4B3F2A4036C7',
} as const;

// TODO -- Remove this when whole monorepo is migrated to GraphQLNET
export const PageFeaturesWithUseGraphQLNET = {
  ...PageFeatures,
  UseGraphQLNET: 'A46B2F16-A240-4B51-B532-6BAAB94115A7',
} as const;
