import { articles } from '@ori/editorial-fetching';

import type { ArticlesSectionProps } from '..';

export const articlesSectionProps: ArticlesSectionProps = {
  articles,
  countOfArticles: articles.length,
  labelRead: 'Read now',
  loadMoreButtonText: 'Load more',
  mode: 'featured',
  title: 'Featured articles',
  getPaginationInfoText: (value, max) => `Showing ${value} out of ${max} articles`,
};
