import { Logger } from '@ori/logger';

import { PACKAGE_NAME, TEAM_NAME } from './constants-generated';

export const mainLogger = new Logger(PACKAGE_NAME, { teamName: TEAM_NAME });

export const mypagesMainLogger = new Logger(PACKAGE_NAME, {
  part: 'mypages',
  teamName: TEAM_NAME,
});

export const campaignsMainLogger = new Logger(PACKAGE_NAME, {
  part: 'campaigns',
  teamName: TEAM_NAME,
});

export const landingMainLogger = new Logger(PACKAGE_NAME, {
  part: 'landing',
});
