import { ArticleBox } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';
import React from 'react';

import { Title } from '../Title';
import { ARTICLES_SECTION } from './constants';
import { getPaginationLoadMoreButtonVisibility } from './getPaginationLoadMoreButtonVisibility';
import { getPaginationProgress } from './getPaginationProgress';
import { Articles, Carousel, Pagination, Root } from './styles';
import type { ArticlesSectionProps } from './types';
import { useGetArticles } from './useGetArticles';

/**
 * Returns section with instances of `ArticleBox` and `Title`.
 * Renders if there is at least 1 article in `listing` mode with thruthy data.
 * Renders if there are at least 3 articles in `featured` mode with thruthy data.
 */
export const ArticlesSection: FC<ArticlesSectionProps> = ({
  articles: initialArticles,
  className,
  countOfArticles,
  labelRead,
  loadMoreButtonText,
  mode,
  title,
  getPaginationInfoText,
}) => {
  const { getTestId } = useTestId();
  const { articles, loadingArticles, loadMore } = useGetArticles({
    articles: initialArticles,
    countOfArticles,
  });
  const progress = getPaginationProgress(articles.length, countOfArticles);
  const paginationLoadMoreButtonVisible = getPaginationLoadMoreButtonVisibility(articles.length, countOfArticles);
  if (articles.length < 1) {
    return null;
  }

  return (
    <Root
      className={className}
      data-testid={getTestId(ARTICLES_SECTION)}
    >
      <Title title={title} />
      {mode === 'listing' ? (
        <Articles>
          {articles.map(({ categoryTitle, image, introText, isVideoArticle, link, title: articleTitle }) => (
            <ArticleBox
              key={articleTitle}
              labelRead={labelRead}
              labelWatch=""
              categoryTitle={categoryTitle}
              image={image}
              introText={introText}
              isVideoArticle={isVideoArticle}
              link={link}
              title={articleTitle}
            />
          ))}
        </Articles>
      ) : (
        <Carousel
          data={articles}
          labelRead={labelRead}
          labelWatch=""
          id="editorials-articles-section-carousel"
        />
      )}
      {mode === 'listing' && countOfArticles !== undefined && loadMoreButtonText && getPaginationInfoText ? (
        <Pagination
          button={{
            label: loadMoreButtonText,
            loading: loadingArticles,
            visible: paginationLoadMoreButtonVisible,
            onClick: loadMore,
          }}
          infoText={getPaginationInfoText(articles.length, countOfArticles)}
          progress={progress}
        />
      ) : null}
    </Root>
  );
};
