import type { Theme } from '@ori-ui/mui';
import { useMediaQuery, useTheme } from '@ori-ui/mui';
import type { CarouselProps } from '@ori/presentation-components';
import { useMemo } from 'react';

/**
 * Hook for generating Categories Carousel options.
 * @returns Categories Carousel options.
 */
export const useCarouselOptions = (): Omit<CarouselProps, 'children'> => {
  const {
    breakpoints: {
      values: { sm },
    },
  } = useTheme();
  const showArrows = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));

  return useMemo(
    () => ({
      navigation: { position: 'outside', showArrows, size: 'medium' },
      pagination: { show: false },
      slider: {
        loop: false,
        slides: { perView: 'auto', spacing: 10 },
        breakpoints: {
          [`(min-width: ${sm}px)`]: {
            slides: { perView: 'auto', spacing: 40 },
          },
        },
      },
    }),
    [showArrows, sm],
  );
};
