import { Typography } from '@ori-ui/mui';
import { EditorialCarousel } from '@ori/presentation-components';
import useTestId from '@ori/testid-generator';
import type { FC } from 'react';

import { EDITORIAL_CAROUSEL_SECTION } from './constants';
import { CarouselContainer, Root } from './styles';
import type { EditorialCarouselSectionProps } from './types';

export const EditorialCarouselSection: FC<EditorialCarouselSectionProps> = ({ title, data, labelRead, id }) => {
  const { getTestId } = useTestId();

  if (data.length === 0) {
    return null;
  }

  return (
    <Root data-testid={getTestId(EDITORIAL_CAROUSEL_SECTION)}>
      <Typography
        variant="h3"
        component="h3"
        align="center"
        color="textPrimary"
      >
        {title}
      </Typography>
      <CarouselContainer>
        <EditorialCarousel
          data={data}
          labelRead={labelRead}
          labelWatch=""
          id={id}
        />
      </CarouselContainer>
    </Root>
  );
};
